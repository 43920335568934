import { Refresh } from "@mui/icons-material";
import { Box, Chip, Grid, IconButton, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import AppTable, { IColumn } from "../../../components/AppTable";

import { RequestsController } from "./requests.controller";

import { IRequest } from "@src/models/request/request.model";
import ArrowUpIcon from "@src/icons/ArrowUpIcon";
import ArrowDownIcon from "@src/icons/ArrowDownIcon";

import RequestFilters from "./components/request-filters";
import RequestsStats from "./components/requests-stats";

const columns: IColumn<IRequest>[] = [
  {
    field: "bitrixId",
    label: "ID",
    width: 50,
  },

  {
    field: "createdAt.formatted",
    label: "Создана",
    sortKey: "createdAt",
    width: 50,
  },
  {
    field: "stageName",
    label: "Текущий этап",
    width: 100,
    sortKey: "stage",
    render(record, value) {
      return <Chip color={value["color"]} label={value["name"]} />;
    },
  },
  {
    field: "updatedAt.formatted",
    label: "Обновлено",
    sortKey: "updatedAt",
    width: 50,
  },
  {
    field: "countryLoading",
    label: "Направление",
    width: 250,
    render(record, value) {
      return (
        <Stack spacing={1}>
          <Box display={"flex"} flexDirection="row" alignItems={"center"}>
            <ArrowUpIcon />
            <Typography>{record.fullAddressLoading}</Typography>
          </Box>
          <Box display={"flex"} flexDirection="row" alignItems={"center"}>
            <ArrowDownIcon />
            <Typography>{record.fullAddressUnloading}</Typography>
          </Box>
        </Stack>
      );
    },
  },
  {
    field: "company.name",
    label: "Компания",
    width: 100,
  },
  {
    field: "userId",
    label: "Менеджер",
    width: 100,
    render(record, value) {
      return `${record.user?.fullname}`;
    },
  },
];

export default observer(function () {
  return (
    <Box>
      <Grid container mb={2} rowGap={2} alignItems="center">
        <Grid item xs={8} display={"flex"}>
          <Typography variant="h1">Запросы</Typography>
          <IconButton size="large" onClick={RequestsController.refresh}>
            <Refresh color="primary" fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <RequestsStats />
        </Grid>
        <Grid item xs={12}>
          <RequestFilters />
        </Grid>
      </Grid>

      <AppTable<IRequest> list={RequestsController.list} columns={columns} />
    </Box>
  );
});
