import { Refresh } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import AppTable, { IColumn } from "../../../components/AppTable";

import { PaymentsController } from "./payments.controller";
import { IPayment } from "@src/models/payment/payment.model";
import PaymentsTabs from "../components/payments-tabs";

const columns: IColumn<IPayment>[] = [
  {
    field: "referenceNumber",
    label: "РЕФ Номер",
  },
  {
    field: "num",
    label: "Номер счета",
  },
  {
    field: "dateAt.formatted",
    label: "Дата выставления счета",
    sortKey: "dateAt",
  },
  {
    field: "amount",
    label: "Сумма счета",
  },
  {
    field: "payUntilAt.formatted",
    label: "Оплатить до",
    sortKey: "payUntilAt",
  },
  {
    field: "paidAt.formatted",
    label: "Дата оплаты",
    sortKey: "paidAt",
  },
  {
    field: "debt",
    label: "Задолженность",
    render(record, value) {
      return (
        <Typography
          fontWeight={500}
          color={value ? "error.main" : "primary.main"}
        >
          {value}
        </Typography>
      );
    },
  },
];

export default observer(function CompaniesPage() {
  return (
    <Box>
      <PaymentsTabs onUploadSuccess={PaymentsController.list.refresh} />
      <Grid container mb={2} alignItems="center">
        <Grid item xs={8} display={"flex"}>
          <Typography variant="h1">Счета</Typography>
          <IconButton size="large" onClick={PaymentsController.list.refresh}>
            <Refresh color="primary" fontSize="large" />
          </IconButton>
        </Grid>
        {/* <Grid item xs={4} display="flex" justifyContent="flex-end">
          <Button onClick={PaymentsController.add} startIcon={<AddCircle />}>
            Добавить компанию
          </Button>
        </Grid> */}
      </Grid>
      <AppTable<IPayment> list={PaymentsController.list} columns={columns} />
    </Box>
  );
});
