import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { BaseModel } from "../base/base-model";
import { BitrixTypeIdDict, BitrixTypeIdModel } from "../request/request-type.enum";

export const RequestTransportModel = BaseModel.named("RequestTransport")
  .props({
    id: types.identifier,
    name: types.string,
    type: BitrixTypeIdModel,
  })
  .views((self) => ({
    get typeName() {
      return BitrixTypeIdDict[self.type].title;
    },
  }));

export interface IRequestTransportType extends Instance<typeof RequestTransportModel> {}
export interface IRequestTransportTypeSnapshot extends SnapshotOut<typeof RequestTransportModel> {}
