import { Backdrop, Box, CircularProgress } from "@mui/material";
import Image from "mui-image";
import React from "react";

export default function AppRouterLoader() {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
      exit
      appear
      in
    >
      <Box
        display={"flex"}
        flexDirection="column"
        justifyContent={"center"}
        alignItems="center"
      >
        <Image
          duration={0}
          showLoading
          src="/images/white_logo.png"
          width={150}
          style={{
            marginBottom: "20px",
          }}
        />
        <CircularProgress color="inherit" />
      </Box>
    </Backdrop>
  );
}
