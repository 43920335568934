import { Divider, Drawer, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { Form, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { ApiClient } from "../../../../services/api_client";
import { toast } from "react-toastify";

import AppInput from "@src/components/AppInput";
import * as yup from "yup";
import AppSelect from "@src/components/AppSelect";
import { CountryRegionList } from "@src/models/country/country.model";

interface CountryForm {
  name: string | null;
  region: string | null;
}

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

export const CountrySchema = yup.object({
  name: yup.string().required().nullable(),
  region: yup.string().required().nullable(),
});

export default observer(function CountryFormDrawer({
  open,

  onClose,
  onSave,
}: Props) {
  const [loading, setLoading] = useState(false);
  const submit = async (values: CountryForm) => {
    try {
      setLoading(true);
      await ApiClient.post("/countries", values);
      onSave();
      onClose();
      toast.success("Успешно добавлено!");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "#fff",
          p: 3,
          maxWidth: 600,
          minWidth: 600,
          boxSizing: "border-box",
        },
      }}
    >
      <Typography variant="h2" mb={1}>
        Страна
      </Typography>
      <Divider />
      <Formik<CountryForm>
        initialValues={{
          name: null,
          region: null,
        }}
        validationSchema={CountrySchema}
        onSubmit={submit}
      >
        <Form>
          <AppInput required name="name" label="Название" />
          <AppSelect required items={CountryRegionList} label="Регион" name="region" />
          <LoadingButton fullWidth loading={loading} type="submit" variant="contained" sx={{ mt: 1 }}>
            Добавить
          </LoadingButton>
        </Form>
      </Formik>
    </Drawer>
  );
});
