import { ISelectItem } from "../../components/AppSelect";

export enum UserRoleEnum {
  director = "director",
  worker = "worker",
}

export const UserRoleEnumDict = {
  [UserRoleEnum.director]: "Директор",
  [UserRoleEnum.worker]: "Менеджер",
} as const;

export const UserRoleEnumList: ISelectItem[] = Object.entries(
  UserRoleEnumDict
).map(([value, label]) => ({ value, label }));

export const UserRoleEnumArray = Object.values(UserRoleEnum);
