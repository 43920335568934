import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import RequestsStat from "./requests-stat";
import { RequestsController } from "../requests.controller";
import { useSearchParams } from "react-router-dom";

export default observer(() => {
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const params: any = {};
    searchParams.forEach((v, k) => {
      params[k] = v;
    });
    function onFocus(event: any) {
      RequestsController.fetchStats(params);
    }
    window.addEventListener("focus", onFocus, false);
    RequestsController.fetchStats(params);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [searchParams]);
  return (
    <Grid container spacing={1}>
      <Grid item>
        <RequestsStat label="Активных запросов" count={RequestsController.stats.requests} />
      </Grid>
      <Grid item>
        <RequestsStat label="Перевозка подтверждена" count={RequestsController.stats.requestsComplete} />
      </Grid>
      <Grid item>
        <RequestsStat label="Сделка провалена" count={RequestsController.stats.requestsFail} />
      </Grid>
      <Grid item>
        <RequestsStat label="Перевозка в пути" count={RequestsController.stats.transportations} />
      </Grid>
      <Grid item>
        <RequestsStat label="Завершена (не оплачена)" count={RequestsController.stats.transportationsArrived} />
      </Grid>
      <Grid item>
        <RequestsStat label="Завершена (оплачена)" count={RequestsController.stats.transportationsPaid} />
      </Grid>
    </Grid>
  );
});
