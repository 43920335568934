import { Divider, Drawer, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";

import { Form, FormikProvider, useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { ApiClient } from "../../../../services/api_client";
import { toast } from "react-toastify";

import * as yup from "yup";

import AppFormikSelect from "@src/components/AppFormikSelect";
import { IBaseDictionaryItem } from "@src/models/base/base-dictionary-item.model";
import CountryGroupFormDirectionSelect from "./country-group-form-direction-select";

export interface CountryGroupForm {
  to?: string | null;
  from?: string | null;
  regionFrom?: string | null;
  regionTo?: string | null;
  bitrixCountryGroupId?: string | null;
  bitrixCountryGroupName?: string | null;
}

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

export const CountryGroupSchema = yup.object().shape(
  {
    from: yup
      .string()
      .when("regionFrom", {
        is: (v: string | null) => v === null,
        then: (s) => s.required(),
        otherwise: (s) => s.notRequired(),
      })
      .nullable(),
    to: yup
      .string()
      .when("regionTo", {
        is: (v: string | null) => v === null,
        then: (s) => s.required(),
        otherwise: (s) => s.notRequired(),
      })
      .nullable(),

    regionFrom: yup
      .string()
      .when("from", {
        is: (v: string | null) => v === null,
        then: (s) => s.required(),
        otherwise: (s) => s.notRequired(),
      })
      .nullable(),
    regionTo: yup
      .string()
      .when("to", {
        is: (v: string | null) => v === null,
        then: (s) => s.required(),
        otherwise: (s) => s.notRequired(),
      })
      .nullable(),
    bitrixCountryGroupId: yup.string().required().nullable(),
    bitrixCountryGroupName: yup.string().required().nullable(),
  },
  [
    ["from", "regionFrom"],
    ["to", "regionTo"],
  ]
);

export default observer(function CountryGroupFormDrawer({ open, onClose, onSave }: Props) {
  const [loading, setLoading] = useState(false);
  const submit = async (values: CountryGroupForm) => {
    try {
      setLoading(true);
      await ApiClient.post("/country-groups", values);
      onSave();
      onClose();
      toast.success("Успешно добавлено!");
      formik.resetForm();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      to: null,
      from: null,
      bitrixCountryGroupId: null,
      bitrixCountryGroupName: null,
      regionFrom: null,
      regionTo: null,
    },
    validationSchema: CountryGroupSchema,
    onSubmit: submit,
  });

  const onSelectBitrixCountryGroup = useCallback((v: IBaseDictionaryItem) => {
    formik.setFieldValue("bitrixCountryGroupName", v.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "#fff",
          p: 3,
          maxWidth: 600,
          minWidth: 600,
          boxSizing: "border-box",
        },
      }}
    >
      <Typography variant="h2" mb={1}>
        Группа агентов
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <FormikProvider value={formik}>
        <Form>
          <CountryGroupFormDirectionSelect label="Погрузка" countryFieldName="from" regionFieldName="regionFrom" />

          <Divider
            sx={{
              my: 2,
            }}
          />
          <CountryGroupFormDirectionSelect label="Выгрузка" countryFieldName="to" regionFieldName="regionTo" />

          <Divider
            sx={{
              mt: 2,
            }}
          />

          <AppFormikSelect<IBaseDictionaryItem>
            required
            url="/country-groups/bitrix"
            label="Группа агента"
            name="bitrixCountryGroupId"
            onSelect={onSelectBitrixCountryGroup}
          />

          <LoadingButton fullWidth loading={loading} type="submit" variant="contained" sx={{ mt: 1 }}>
            Добавить
          </LoadingButton>
        </Form>
      </FormikProvider>
    </Drawer>
  );
});
