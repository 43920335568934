import { IAnyType, SnapshotIn, types } from "mobx-state-tree";

import { DateTime, Settings } from "luxon";

Settings.defaultLocale = "ru-RU";
Settings.defaultZone = "Asia/Almaty";
export const formatDate = (
  value: any,
  displayFormat = "dd.MM.yyyy HH:mm",
  valueFormat?: string
) => {
  if (!value) {
    return value;
  }
  const date = valueFormat
    ? DateTime.fromFormat(value, valueFormat)
    : DateTime.fromISO(value);

  return date.toFormat(displayFormat);
};

const NUMBER_OR_STRING = types.union(types.string, types.number);

export const NULLABLE_UNION_STRING_NUMBER = types.maybeNull(NUMBER_OR_STRING);

export const NULLABLE_BOOLEAN = types.maybeNull(types.boolean);
export const NULLABLE_STRING = types.maybeNull(types.string);
export const NULLABLE_NUMBER = types.maybeNull(types.number);
export const PARSED_NUMBER = types.optional(types.number, 0, [null, undefined]);
export const PARSED_BOOLEAN = types.optional(types.boolean, false, [
  null,
  undefined,
]);

export const OPTIONAL_STRING = types.optional(types.string, "", [
  null,
  undefined,
]);
export const OPTIONAL_BOOLEAN = types.optional(types.boolean, false, [
  null,
  undefined,
]);

export const NULLABLE_ARRAY = <T extends IAnyType>(
  model: T,
  defaultValue: SnapshotIn<T>[] = []
) => types.optional(types.array(model), defaultValue, [null, undefined]);

export const STRING_OR_NUMBER = types.optional(
  types.union(types.string, types.number),
  "",
  [null, undefined]
);

export const ParseToArrayModel = (
  type: IAnyType,
  defaultValue?: (string | number)[]
) =>
  types.snapshotProcessor(NULLABLE_ARRAY(type, defaultValue), {
    preProcessor: (sn: any) => {
      return sn && !Array.isArray(sn) ? [isNaN(+sn) ? sn : +sn] : sn;
    },
  });

const DateBaseModel = types.model({ value: NULLABLE_STRING });

interface IDateModelProps {
  displayFormat?: string;
  valueFormat?: string;
}

export const DateModel = (
  data: IDateModelProps = {
    displayFormat: "dd.MM.yyyy, HH:mm",
  }
) => {
  const model = types.maybeNull(
    DateBaseModel.views((self) => ({
      get formatted(): string {
        return formatDate(self.value, data.displayFormat, data.valueFormat);
      },
    }))
  );
  return types.snapshotProcessor(model, {
    preProcessor: (value: any) => {
      return { value };
    },
    postProcessor: (sn) => {
      return sn ? sn.value : null;
    },
  });
};
