import { types } from "mobx-state-tree";
import { toast } from "react-toastify";
import { AppLoadingController } from "../../../components/AppLoading";
import { IColumnAction } from "../../../components/AppTable";
import { confirmDialogContoller } from "../../../dialogs/confirm/confirm-dialog";

import { ApiClient } from "../../../services/api_client";
import { CountriesListModel } from "@src/models/country/countries-list.model";
import { CountryModel, ICountry } from "@src/models/country/country.model";

const CountriesControllerModel = types
  .model("CountriesController", {
    list: types.optional(CountriesListModel, { url: "/countries" }),
    isOpenForm: false,
    item: types.maybeNull(types.reference(CountryModel)),
  })
  .actions((self) => {
    return {
      toggleForm() {
        if (self.isOpenForm) {
          self.item = null;
        }
        self.isOpenForm = !self.isOpenForm;
      },
      add() {
        self.item = null;
        self.isOpenForm = !self.isOpenForm;
      },
      async onAction(action: IColumnAction, row: ICountry) {
        switch (action) {
          case "delete":
            confirmDialogContoller.open(
              async () => {
                try {
                  AppLoadingController.show();
                  await ApiClient.delete(`${self.list.url}/${row.id}`);
                  self.list.refresh();
                  toast.success("Удалено");
                } catch (error) {
                } finally {
                  AppLoadingController.hide();
                }
              },
              "Удаление",
              `Вы уверены что хотите удалить страну ${row.name}?`
            );
            break;
          case "edit":
            self.item = row;
            self.isOpenForm = true;
            break;
        }
      },
    };
  });

export const CountriesController = CountriesControllerModel.create();
