import { ISelectItem } from "@src/components/AppSelect";

export enum RequestCurrencyEnum {
  KZT = "KZT",
  USD = "USD",
  EURO = "EUR",
  RUB = "RUB",
}

export const CURRENCIES: ISelectItem[] = Object.entries(RequestCurrencyEnum).map(([label, value]) => ({
  label,
  value,
}));
