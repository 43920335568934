import { types } from "mobx-state-tree";
import { toast } from "react-toastify";
import { AppLoadingController } from "../../../components/AppLoading";
import { IColumnAction } from "../../../components/AppTable";
import { confirmDialogContoller } from "../../../dialogs/confirm/confirm-dialog";

import { IUserModel, UserModel } from "../../../models/user/user.model";
import { UsersListModel } from "../../../models/user/users-list.model";

import { ApiClient } from "../../../services/api_client";

const UsersControllerModel = types
  .model("UsersController", {
    list: types.optional(UsersListModel, { url: "/users" }),
    isOpenForm: false,
    item: types.maybeNull(types.reference(UserModel)),
  })
  .actions((self) => {
    return {
      toggleForm() {
        if (self.isOpenForm) {
          self.item = null;
        }
        self.isOpenForm = !self.isOpenForm;
      },
      add() {
        self.item = null;
        self.isOpenForm = !self.isOpenForm;
      },
      async onAction(action: IColumnAction, row: IUserModel) {
        switch (action) {
          case "delete":
            confirmDialogContoller.open(
              async () => {
                try {
                  AppLoadingController.show();
                  await ApiClient.delete(`${self.list.url}/${row.id}`);
                  await self.list.refresh();
                  toast.success("Удалено");
                } catch (error) {
                } finally {
                  AppLoadingController.hide();
                }
              },
              "Удаление",
              `Вы уверены что хотите удалить пользователя ${row.name}?`
            );
            break;
          case "edit":
            self.item = row;
            self.isOpenForm = true;
            break;
        }
      },
      async restore(row: IUserModel) {
        confirmDialogContoller.open(
          async () => {
            try {
              AppLoadingController.show();
              await ApiClient.patch(`${self.list.url}/${row.id}`, {
                isDeleted: false,
              });
              await self.list.refresh();
              toast.success("Восстановлен");
            } catch (error) {
            } finally {
              AppLoadingController.hide();
            }
          },
          "Восстановить",
          `Вы уверены что хотите восстановить пользователя ${row.name}?`
        );
      },
    };
  });

export const usersController = UsersControllerModel.create();
