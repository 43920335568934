import { Instance, SnapshotIn, types } from "mobx-state-tree";
import { BaseModel } from "../base/base-model";
import { DateModel, NULLABLE_NUMBER } from "../extensions/mobx-types";
export const PaymentModel = types
  .compose(
    BaseModel.props({ id: types.maybe(types.identifier) }),
    types.model().props({
      referenceNumber: types.string,
      num: types.string,
      dateAt: DateModel({ displayFormat: "dd.MM.yyyy" }),
      payUntilAt: DateModel({ displayFormat: "dd.MM.yyyy" }),
      paidAt: DateModel({ displayFormat: "dd.MM.yyyy" }),
      amount: types.number,
      debt: NULLABLE_NUMBER,
    })
  )
  .views((self) => ({
    get isPaid() {
      return !!self.paidAt?.value && self.debt === 0;
    },
  }));

export interface IPayment extends Instance<typeof PaymentModel> {}
export interface IPaymentSnapshot extends SnapshotIn<typeof PaymentModel> {}
