import { Divider, Drawer, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { Form, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { ApiClient } from "../../../../services/api_client";
import { toast } from "react-toastify";

import AppInput from "@src/components/AppInput";
import * as yup from "yup";
import AppSelect from "@src/components/AppSelect";

import { BitrixTypeIdEnum, BitrixTypeIdSelectItems } from "@src/models/request/request-type.enum";
import { RequestTransportTypeController } from "../request-transport.controller";

interface IForm {
  name: string | null;
  type: BitrixTypeIdEnum | null;
}

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

export const YupSchema = yup.object({
  name: yup.string().required().nullable(),
  type: yup.string().required().nullable(),
});

export default observer(function IFormDrawer({
  open,

  onClose,
  onSave,
}: Props) {
  const [loading, setLoading] = useState(false);
  const submit = async (values: IForm) => {
    try {
      setLoading(true);
      if (RequestTransportTypeController.item) {
        await ApiClient.put(`/request-transport-type/${RequestTransportTypeController.item.id}`, values);
      } else {
        await ApiClient.post("/request-transport-type", values);
      }
      onSave();
      onClose();
      toast.success("Сохранено!");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "#fff",
          p: 3,
          maxWidth: 600,
          minWidth: 600,
          boxSizing: "border-box",
        },
      }}
    >
      <Typography variant="h2" mb={1}>
        Тип ТПС
      </Typography>
      <Divider />
      <Formik<IForm>
        initialValues={{
          name: RequestTransportTypeController.item?.name ?? null,
          type: RequestTransportTypeController.item?.type ?? null,
        }}
        validationSchema={YupSchema}
        onSubmit={submit}
      >
        <Form>
          <AppInput required name="name" label="Название" />
          <AppSelect
            required
            items={BitrixTypeIdSelectItems.filter((x) => x.value !== BitrixTypeIdEnum.avto)}
            label="Тип перевозки"
            name="type"
          />
          <LoadingButton fullWidth loading={loading} type="submit" variant="contained" sx={{ mt: 1 }}>
            Сохранить
          </LoadingButton>
        </Form>
      </Formik>
    </Drawer>
  );
});
