import {
  AppBar,
  Box,
  Toolbar,
  Container,
  Card,
  Typography,
  Grid,
} from "@mui/material";
import Image from "mui-image";
import { Form, Formik } from "formik";
import AppPhoneInput from "../../components/AppPhoneInput";
import AppPasswordInput from "../../components/AppPasswordInput";
import { MainTheme, ThemeColors } from "../../customization/theme";
import { Observer, observer } from "mobx-react-lite";
import { ILoginForm, LoginController, LoginSchema } from "./login.contoller";

import { LoadingButton } from "@mui/lab";
import { Navigate } from "react-router-dom";

export default observer(function LoginPage() {
  const token = localStorage.getItem("access_token");
  if (token) {
    return <Navigate to={"/"} replace />;
  }

  return (
    <Box position={"relative"}>
      <Box
        sx={{
          position: "relative",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          zIndex: 2,
        }}
      >
        <AppBar
          sx={{
            backgroundColor: "#fff",
          }}
          position="relative"
        >
          <Container maxWidth="lg">
            <Toolbar>
              <Image src="/images/logo.png" width={205} />
            </Toolbar>
          </Container>
        </AppBar>

        <Container
          sx={{
            flex: 1,
            alignItems: "center",

            display: "flex",
            [MainTheme.breakpoints.down("sm")]: {
              alignItems: "flex-start",
              marginTop: "60px",
            },
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent={"center"}
            flex={1}
          >
            <Grid item xs={12} sm={12} md={5}>
              <Card
                sx={{
                  padding: "30px",
                }}
              >
                <Typography variant="h1">Авторизация</Typography>
                <Typography variant="h4" color="grey.900">
                  Панель администратора
                </Typography>
                <Formik<ILoginForm>
                  initialValues={{
                    phone: "",
                    password: "",
                  }}
                  validationSchema={LoginSchema}
                  onSubmit={LoginController.submit}
                >
                  {({ handleChange, isValid, submitForm }) => (
                    <Form>
                      <AppPhoneInput
                        disabled={LoginController.loading}
                        name="phone"
                        label={"Номер телефона"}
                      />
                      <AppPasswordInput
                        disabled={LoginController.loading}
                        name="password"
                        label="Пароль"
                        onSubmit={submitForm}
                      />

                      <Observer>
                        {() => (
                          <LoadingButton
                            loading={LoginController.loading}
                            disabled={!isValid}
                            onClick={submitForm}
                            variant="contained"
                            sx={{ mt: 1, minWidth: 230 }}
                          >
                            Войти
                          </LoadingButton>
                        )}
                      </Observer>
                    </Form>
                  )}
                </Formik>
              </Card>
            </Grid>
          </Grid>
        </Container>

        <Box
          sx={{
            borderTopWidth: 1,
            borderTopColor: ThemeColors.gray50,
            borderTopStyle: "solid",
          }}
        >
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              height: 90,
            }}
          >
            <Typography variant="h4" color={ThemeColors.gray}>
              «Interlink Global services» © {new Date().getFullYear()}. All
              rights reserved
            </Typography>
          </Container>
        </Box>
      </Box>
    </Box>
  );
});
