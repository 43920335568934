import { Instance, SnapshotIn, types } from "mobx-state-tree";
import { BaseModel } from "../base/base-model";

export const AdminModel = types.compose(
  BaseModel.props({ id: types.maybe(types.identifier) }),
  types.model("Admin", {
    phone: types.string,
    name: types.string,
  })
);

export interface IAdminModel extends Instance<typeof AdminModel> {}
export interface IAdminSnapshopIn extends SnapshotIn<typeof AdminModel> {}
