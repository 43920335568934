import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { observer } from "mobx-react-lite";

import { BaseDialogModel } from "../../models/base/base-dialog";

const ConfirmDialogController = BaseDialogModel.named("ConfirmDialogController")
  .props({
    title: "Вы уверены?",
    text: "Подтвердите",
  })
  .actions((self) => {
    let _callback: Function = () => {};
    return {
      open(onOk: Function, title = "Вы уверены?", text = "Подтвердите") {
        self.isOpen = true;
        self.title = title;
        self.text = text;
        _callback = onOk;
      },
      onOk: () => {
        self.close();
        _callback();
      },
    };
  });

export const confirmDialogContoller = ConfirmDialogController.create();

export default observer(function ConfirmDialog() {
  return (
    <Dialog
      open={confirmDialogContoller.isOpen}
      onClose={confirmDialogContoller.close}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {confirmDialogContoller.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {confirmDialogContoller.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box px={2} py={1}>
          <Button
            variant="text"
            size="small"
            onClick={confirmDialogContoller.onCancel}
          >
            Отмена
          </Button>
          <Button
            variant="text"
            size="small"
            onClick={confirmDialogContoller.onOk}
            autoFocus
            color="error"
            sx={{
              ml: 4,
            }}
          >
            Подтвердить
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});
