import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { ILoginForm } from "../pages/login/login.contoller";
import { AppRouter } from "../router/app_router";
class ApiClientAxios {
  access_token: string | null = null;

  refreshAuthLogic = async (failedRequest: AxiosError) => {
    const refresh_token = localStorage.getItem("refresh_token");
    try {
      const { data } = await axios.post(
        `${this._api.defaults.baseURL}/auth/refresh`,
        { refresh_token }
      );

      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("refresh_token", data.refresh_token);

      failedRequest.response!.headers["Authorization"] =
        "Bearer " + data.access_token;
    } catch (error) {
      localStorage.clear();
      toast.error("Сессия истекла, авторизуйтесь заного");
      AppRouter.navigate("/login", { replace: true });
      throw error;
    }

    // console.log(failedRequest);
  };

  private _api: AxiosInstance;

  constructor(baseURL: string) {
    this._api = axios.create({ baseURL, withCredentials: true });
    this._api.interceptors.request.use((request) => {
      const token = localStorage.getItem("access_token");
      if (token) {
        request.headers.set("Authorization", `Bearer ${token}`);
        // request.headers = {
        //   ...request.headers,
        //   Authorization: `Bearer ${token}`,
        // };
      }
      return request;
    });

    createAuthRefreshInterceptor(this._api, this.refreshAuthLogic, {
      pauseInstanceWhileRefreshing: true,
    });

    // Use interceptor to inject the token to requests

    const responseInterceptor = {
      after: (response: AxiosResponse) => {
        if (response.data?.message) {
          toast.info(response?.data?.message);
        }
        return response;
      },
      error: (error: AxiosError<any>) => {
        switch (error.response?.status) {
          case 401:
            break;
          default:
            if (error.response?.data?.message) {
              if (Array.isArray(error.response?.data?.message)) {
                toast.error(error.response?.data?.message[0]);
              } else {
                toast.error(error.response?.data?.message);
              }
            } else {
              toast.error(error.response?.statusText);
            }
            break;
        }

        return Promise.reject(error);
      },
    };

    this._api.interceptors.response.use(
      responseInterceptor.after,
      responseInterceptor.error
    );
  }

  login(v: ILoginForm) {
    return this._api.post("/auth/login", v);
  }

  get get() {
    return this._api.get;
  }
  get post() {
    return this._api.post;
  }
  get delete() {
    return this._api.delete;
  }
  get put() {
    return this._api.put;
  }
  get patch() {
    return this._api.patch;
  }
}

const API_URL = process.env["REACT_APP_API_URL"];
export const ApiClient = new ApiClientAxios(API_URL!);
