import { SvgIcon } from "@mui/material";
import React from "react";

export default function RequestIcon() {
  return (
    <SvgIcon
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2029 13.2898C17.3447 13.2898 14.2058 16.4286 14.2058 20.2867C14.2058 24.145 17.3447 27.2838 21.2029 27.2838C25.0612 27.2838 28.2 24.145 28.2 20.2867C28.2 16.4284 25.0612 13.2898 21.2029 13.2898ZM22.0838 22.1544H20.5756L20.7317 22.2969C21.4989 22.9967 20.4151 24.1972 19.6415 23.4917L17.9439 21.9432C17.5982 21.6278 17.5982 21.0636 17.9439 20.7482L19.6415 19.1995C20.4127 18.4961 21.5005 19.6931 20.7317 20.3945L20.5756 20.537H22.0838C23.4045 20.537 23.4045 18.488 22.0838 18.488H21.399C20.3552 18.488 20.3583 16.8705 21.399 16.8705H22.0838C23.5216 16.8705 24.7256 18.0746 24.7256 19.5125C24.7256 20.9503 23.5216 22.1544 22.0838 22.1544Z"
        fill="white"
      />
      <path
        d="M10.6998 9.02319L12.3387 8.33695C12.5386 8.2532 12.7636 8.2532 12.9635 8.33695L14.6022 9.02319V2.72607H10.6998V9.02319Z"
        fill="white"
      />
      <path
        d="M21.203 11.6722C22.0416 11.6722 22.8522 11.7932 23.6192 12.0178V4.71317C23.6192 3.61745 22.7278 2.72607 21.6321 2.72607H16.2197V10.2387C16.2197 10.5094 16.0843 10.762 15.859 10.912C15.7242 11.0016 15.568 11.0473 15.4108 11.0473C15.305 11.0473 15.199 11.0267 15.0985 10.9846L12.6509 9.95989L10.2034 10.9846C9.95392 11.0892 9.66846 11.0619 9.44309 10.912C9.21771 10.762 9.08237 10.5094 9.08237 10.2387V2.72607H3.66996C2.57424 2.72607 1.68286 3.61745 1.68286 4.71317V22.6753C1.68286 23.771 2.57424 24.6624 3.66996 24.6624H13.7851C13.0254 23.3791 12.5884 21.8833 12.5884 20.287C12.5884 15.5368 16.4529 11.6722 21.203 11.6722Z"
        fill="white"
      />
    </SvgIcon>
  );
}
