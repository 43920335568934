import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";

import { observer } from "mobx-react-lite";
import { Box } from "@mui/material";
import Menus from "./Menus";
import { DashboardController } from "../dashboard.controller";

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create(["width", "transform"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create(["width", "transform"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),

  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})<{ open: boolean }>(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 3),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// const DrawerIcon = styled(ButtonBase, {
//   shouldForwardProp: (prop) => prop !== "open",
// })<{ open: boolean }>(({ theme, open }) => ({
//   backgroundColor: "secondary.main",
//   position: "absolute",
//   top: 16,
//   right: 16,

//   zIndex: theme.zIndex.drawer,
//   ...(open && {
//     ...openedMixin(theme),
//     transform: "translate(0px)",
//   }),
//   ...(!open && {
//     ...closedMixin(theme),
//     transform: `translate(${92 - drawerWidth}px)`,
//   }),
//   width: 30,
//   height: 30,
//   minWidth: 30,
//   minHeight: 30,
//   borderRadius: 0,
//   [theme.breakpoints.up("sm")]: {
//     width: 30,
//   },
//   "& .MuiTouchRipple-root": {
//     width: 33,
//     height: 33,
//     borderRadius: 5,
//   },
// }));

export default observer(function SideDrawer() {
  return (
    <Box position={"relative"} width={drawerWidth}>
      <Drawer variant="permanent" open={DashboardController.isDrawerOpen}>
        <DrawerHeader open={DashboardController.isDrawerOpen}>
          <img
            alt="logo"
            style={{
              transform: DashboardController.isDrawerOpen ? "scale(1)" : "scale(0.4) translateX(-150px)",
              transition: "transform 0.25s ease",
            }}
            src="/images/white_logo.png"
            width={132}
          />
        </DrawerHeader>

        <Divider />
        <Menus />
      </Drawer>
      {/* <DrawerIcon
        open={DashboardController.isDrawerOpen}
        onClick={DashboardController.toggleDrawer}
      >
        <img
          src="/images/menu.png"
          alt="menu"
          width={30}
          style={{
            transform: DashboardController.isDrawerOpen ? "none" : "scaleX(-1)",
          }}
        />
      </DrawerIcon> */}
    </Box>
  );
});
