import { AddCircle, Refresh } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import AppTable, { IColumn } from "@src/components/AppTable";
import { IBaseDictionaryController } from "@src/models/base/base-dictionary-controller";

import { observer } from "mobx-react-lite";
import { useLoaderData } from "react-router-dom";
import DictFormDrawer from "./components/dict-form-drawer";
import { IBaseDictionaryItem } from "@src/models/base/base-dictionary-item.model";

const columns: IColumn<IBaseDictionaryItem>[] = [
  {
    field: "name",
    label: "Название",
  },
  {
    field: "enabledText",
    label: "Доступен для выбора",
    sortKey: "enable",
  },
];

export default observer(function CompaniesPage() {
  const controller = useLoaderData() as IBaseDictionaryController;

  return (
    <Box>
      <Grid container mb={2}>
        <Grid item xs display={"flex"}>
          <Typography variant="h1">{controller.name}</Typography>
          <IconButton size="large" onClick={controller.list.refresh}>
            <Refresh color="primary" fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item>
          <Button onClick={controller.add} startIcon={<AddCircle />}>
            Добавить
          </Button>
        </Grid>
      </Grid>
      <AppTable<IBaseDictionaryItem>
        list={controller.list}
        columns={columns}
        actions={["edit", "delete"]}
        onAction={controller.onAction}
      />
      <DictFormDrawer controller={controller} />
    </Box>
  );
});
