import { Backdrop, CircularProgress } from "@mui/material";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";

class AppLoadingClass {
  constructor() {
    makeAutoObservable(this);
  }
  loading = false;

  show() {
    this.loading = true;
  }
  hide() {
    this.loading = false;
  }
}

export const AppLoadingController = new AppLoadingClass();



export default observer(function AppLoadingComponent() {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={AppLoadingController.loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
});
