import { Divider, Drawer, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import "yup-phone";
import * as yup from "yup";
import { Form, Formik } from "formik";

import { LoadingButton } from "@mui/lab";

import { runInAction } from "mobx";
import { applySnapshot } from "mobx-state-tree";
import { toast } from "react-toastify";
import { IUserModel } from "@src/models/user/user.model";
import { ApiClient } from "@src/services/api_client";

import AppInput from "@src/components/AppInput";
import AppPhoneInput from "@src/components/AppPhoneInput";
import AppSelect from "@src/components/AppSelect";
import { UserRoleEnumList } from "@src/models/user/user.role.enum";
import AppFormikSelect from "@src/components/AppFormikSelect";
import { ICompanySnapshot } from "@src/models/company/company.model";
import { AppDomains } from "@src/models/user/domains";

interface Props {
  item: IUserModel | null;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

export interface IUserForm {
  id?: string;
  name: string;
  lastName: string;
  email: string;
  phone: string;
  companyId: string;
  role: string;
  domain: AppDomains | null;
  // password?: string;
}

const FormSchema = yup.object({
  id: yup.string(),
  name: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().phone(undefined, false, "Неверный формат телефона").required(),
  companyId: yup.string().required(),
  role: yup.string().required(),
  domain: yup.string().required(),
  // password: yup.string().when("id", {
  //   is: undefined,
  //   then: yup.string().required(),
  //   otherwise: yup.string().notRequired(),
  // }),
});

export default observer(function CompanyFormDrawer({ open, item, onClose, onSave }: Props) {
  const [loading, setLoading] = useState(false);
  const submit = async (values: IUserForm) => {
    try {
      setLoading(true);
      if (item) {
        const { data } = await ApiClient.put(`/users/${item.id}`, values);
        runInAction(() => {
          applySnapshot(item, data);
        });
      } else {
        await ApiClient.post("/users", values);
        onSave();
      }
      onClose();
      toast.success("Успешно сохранено!");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "#fff",
          p: 3,

          width: 600,
          boxSizing: "border-box",
        },
      }}
    >
      <Typography variant="h2" mb={1}>
        Пользователь
      </Typography>
      <Divider />
      <Formik<IUserForm>
        initialValues={{
          id: item?.id,
          companyId: item?.companyId ?? "",
          email: item?.email ?? "",
          name: item?.name ?? "",
          lastName: item?.name ?? "",
          phone: item?.phone ?? "",
          role: item?.role ?? "",
          domain: null,
          // password: "",
        }}
        validationSchema={FormSchema}
        onSubmit={submit}
      >
        <Form autoComplete="off" aria-autocomplete="none">
          <AppFormikSelect<ICompanySnapshot>
            label="Компания"
            name="companyId"
            url="/companies"
            labelField="name"
            valueField="id"
            defaultValue={item?.company}
            // value={item?.company}
            // initialOption={item?.company}
          />
          <AppInput
            label="Имя"
            name="name"
            autoComplete="off"
            aria-autocomplete="none"
            inputProps={{ "aria-autocomplete": "none" }}
          />
          <AppInput
            label="Фамилия"
            name="lastName"
            autoComplete="off"
            aria-autocomplete="none"
            inputProps={{ "aria-autocomplete": "none" }}
          />
          <AppPhoneInput disabled={loading} name="phone" label={"Номер телефона"} />
          <AppInput label="E-Mail" name="email" />
          <AppSelect name="role" label="Роль" items={UserRoleEnumList} />
          <AppSelect
            name="domain"
            label="Сайт"
            required
            items={[
              { label: "Interlink", value: AppDomains.interink },
              {
                label: "DForward",
                value: AppDomains.dforward,
              },
            ]}
          />

          {/* <AppInput
              name="password"
              label="Пароль"
              helperText="Оставив поле пустым, пароль не имениться"
            /> */}

          <LoadingButton fullWidth loading={loading} type="submit" variant="contained" sx={{ mt: 1 }}>
            Сохранить
          </LoadingButton>
        </Form>
      </Formik>
    </Drawer>
  );
});
