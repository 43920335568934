import "yup-phone";
import * as yup from "yup";
import { ApiClient } from "../../services/api_client";
import { AppRouter } from "../../router/app_router";
import { flow, types } from "mobx-state-tree";

export const LoginSchema = yup.object({
  phone: yup.string().phone(undefined, false, "Неверный формат телефона").required(),
  password: yup.string().required(),
});

export interface ILoginForm {
  phone: string;
  password: string;
}

const LoginControllerModel = types
  .model("LoginControllerModel", {
    loading: false,
  })
  .actions((self) => ({
    submit: flow(function* (values) {
      try {
        self.loading = true;
        const { data } = yield ApiClient.login(values);
        localStorage.setItem("access_token", data.access_token);
        localStorage.setItem("refresh_token", data.refresh_token);
        AppRouter.navigate("/dashboard", { replace: true });
      } catch (error) {
        console.log(error);
      } finally {
        self.loading = false;
      }
    }),
  }));

export const LoginController = LoginControllerModel.create();
