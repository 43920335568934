import { AddCircle, Delete, Refresh } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { IUserModel } from "@src/models/user/user.model";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import AppTable, { IColumn } from "../../../components/AppTable";

import UserFormDrawer from "./components/user-form-drawer";
import { usersController } from "./users.controller";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
const columns: IColumn<IUserModel>[] = [
  {
    field: "bitrixId",
    label: "ID",
  },
  {
    field: "fullname",
    label: "ФИО",
  },
  {
    field: "company.name",
    label: "Компания",
  },
  {
    field: "phone",
    label: "Номер телефона",
  },
  {
    field: "email",
    label: "E-Mail",
  },
  {
    field: "roleName",
    label: "Роль",
    sortKey: "role",
  },
];

export default observer(function CompaniesPage() {
  let [searchParams, setSearchParams] = useSearchParams();
  const isDeletedActive = useMemo(
    () => searchParams.get("isDeleted") === "true",
    [searchParams]
  );
  const onToggeIsDeleted = useCallback(
    (_: any, value: boolean) => {
      setSearchParams((prev) => {
        prev.set("isDeleted", String(value));
        return prev;
      });
    },
    [setSearchParams]
  );

  return (
    <Box>
      <Grid container mb={2} alignItems="center">
        <Grid item xs={8} display={"flex"}>
          <Typography variant="h1">Пользователи</Typography>
          <IconButton size="large" onClick={usersController.list.refresh}>
            <Refresh color="primary" fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <Button onClick={usersController.add} startIcon={<AddCircle />}>
            Добавить пользователя
          </Button>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch checked={isDeletedActive} onChange={onToggeIsDeleted} />
            }
            label="Показать архивных"
          />
        </Grid>
      </Grid>

      <AppTable<IUserModel>
        list={usersController.list}
        columns={columns}
        onAction={usersController.onAction}
        actions={["edit"]}
        extraActions={(row) => (
          <React.Fragment>
            {row.isDeleted ? (
              <Tooltip title="Восстановить">
                <IconButton onClick={() => usersController.restore(row)}>
                  <RestoreFromTrashIcon color="success" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Удалить">
                <IconButton
                  onClick={() => usersController.onAction("delete", row)}
                >
                  <Delete color="error" />
                </IconButton>
              </Tooltip>
            )}
          </React.Fragment>
        )}
      />
      <UserFormDrawer
        open={usersController.isOpenForm}
        onClose={usersController.toggleForm}
        onSave={usersController.list.refresh}
        item={usersController.item}
      />
    </Box>
  );
});
