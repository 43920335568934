import { SvgIcon } from "@mui/material";
import React from "react";

export default function ArrowUpIcon() {
  return (
    <SvgIcon
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4317 9.60608C15.3594 9.68031 15.2601 9.72199 15.1565 9.72159H13.1377C12.9802 9.72159 12.8525 9.84926 12.8525 10.0067V16.3198C12.8525 16.5308 12.6815 16.7019 12.4704 16.7019H8.54115C8.33012 16.7019 8.15906 16.5308 8.15906 16.3198V10.0067C8.15906 9.84926 8.03139 9.72159 7.87391 9.72159H5.8494C5.6384 9.72557 5.46413 9.55777 5.46015 9.3468C5.45815 9.24118 5.49998 9.13944 5.57566 9.06577L10.2292 4.41222C10.3 4.34031 10.3963 4.2993 10.4973 4.29816C10.6001 4.29778 10.6988 4.33889 10.771 4.41222L15.4245 9.06577C15.5757 9.21298 15.5789 9.45488 15.4317 9.60608Z"
        fill="#27AE60"
      />
    </SvgIcon>
  );
}
