import { types } from "mobx-state-tree";
import { toast } from "react-toastify";
import { AppLoadingController } from "../../../components/AppLoading";
import { IColumnAction } from "../../../components/AppTable";
import { confirmDialogContoller } from "../../../dialogs/confirm/confirm-dialog";

import { ApiClient } from "../../../services/api_client";

import { IRequestTransportType, RequestTransportModel } from "@src/models/request-transport/request-transport.model";
import { BaseListModel } from "@src/models/base/base-list-model";

const RequestTransportTypeControllerModel = types
  .model("RequestTransportTypeController", {
    list: types.optional(BaseListModel(RequestTransportModel), { url: "/request-transport-type" }),
    isOpenForm: false,
    item: types.maybeNull(types.reference(RequestTransportModel)),
  })
  .actions((self) => {
    return {
      toggleForm() {
        if (self.isOpenForm) {
          self.item = null;
        }
        self.isOpenForm = !self.isOpenForm;
      },
      add() {
        self.item = null;
        self.isOpenForm = !self.isOpenForm;
      },
      async onAction(action: IColumnAction, row: IRequestTransportType) {
        switch (action) {
          case "delete":
            confirmDialogContoller.open(
              async () => {
                try {
                  AppLoadingController.show();
                  await ApiClient.delete(`${self.list.url}/${row.id}`);
                  self.list.refresh();
                  toast.success("Удалено");
                } catch (error) {
                } finally {
                  AppLoadingController.hide();
                }
              },
              "Удаление",
              `Вы уверены что хотите удалить  "${row.name}" ?`
            );
            break;
          case "edit":
            self.item = row;
            self.isOpenForm = true;
            break;
        }
      },
    };
  });

export const RequestTransportTypeController = RequestTransportTypeControllerModel.create();
