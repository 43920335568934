import { AddCircle, Refresh } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import AppTable, { IColumn } from "../../../components/AppTable";

import CompanyFormDrawer from "./components/country-group-form-drawer";
import { ICountryGroup } from "@src/models/country-group/country-group.model";
import { CountryGroupController } from "./country-groups.controller";

const columns: IColumn<ICountryGroup>[] = [
  {
    field: "from",
    label: "Место погрузки",
    render(record, value) {
      return record?.from ?? record.regionFrom;
    },
  },
  {
    field: "to",
    label: "Место выгрузки",
    render(record, value) {
      return record?.to ?? record.regionTo;
    },
  },
  {
    field: "bitrixCountryGroupName",
    label: "Группа агентов",
  },
];

export default observer(function CompaniesPage() {
  return (
    <Box>
      <Grid container mb={2} alignItems="center">
        <Grid item xs={8} display={"flex"}>
          <Typography variant="h1">Группы агентов</Typography>
          <IconButton size="large" onClick={CountryGroupController.list.refresh}>
            <Refresh color="primary" fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <Button onClick={CountryGroupController.add} startIcon={<AddCircle />}>
            Добавить
          </Button>
        </Grid>
      </Grid>
      <AppTable<ICountryGroup>
        list={CountryGroupController.list}
        columns={columns}
        actions={["delete"]}
        onAction={CountryGroupController.onAction}
      />

      <CompanyFormDrawer
        open={CountryGroupController.isOpenForm}
        onClose={CountryGroupController.toggleForm}
        onSave={CountryGroupController.list.refresh}
      />
    </Box>
  );
});
