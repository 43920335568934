import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import { useMatch } from "react-router-dom";

import AppLink from "../../../components/AppLink";
import { DashboardController } from "../dashboard.controller";

interface Props {
  label: string;
  icon: ReactNode;
  to: string;
  children?: Props[];
}

export default observer(function MenuItem({ label, icon, to }: Props) {
  const match = useMatch(to);

  return (
    <ListItem component={AppLink} to={to} disablePadding sx={{ display: "block" }}>
      <ListItemButton
        selected={!!match}
        sx={{
          minHeight: 48,
          justifyContent: DashboardController.isDrawerOpen ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: DashboardController.isDrawerOpen ? 3 : "auto",
            justifyContent: "center",
            color: "#fff",
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={label} sx={{ opacity: DashboardController.isDrawerOpen ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
});
