import { Link, LinkProps } from "@mui/material";
import { NavLink } from "react-router-dom";
import React from "react";

const NavLinkBase = React.forwardRef<any, any>((props, ref) => (
  <NavLink
    ref={ref}
    to={props.to}
    className={({ isActive }) =>
      `${props.className} ${isActive ? "Mui-selected" : ""}`
    }
    {...props}
  >
    {props.children}
  </NavLink>
));

const AppLink = React.forwardRef<LinkProps, any>((props, ref) => (
  <Link component={NavLinkBase} ref={ref} to={props.to} {...props}>
    {props.children}
  </Link>
));
export default AppLink;
