import { Refresh } from "@mui/icons-material";
import { Box, Grid, IconButton, Link, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import AppTable, { IColumn } from "../../../components/AppTable";

import PaymentsTabs from "../components/payments-tabs";
import { PaymentsFilesController } from "./payments-files.controller";
import { IPaymentFile } from "@src/models/payment-file/payments-file.model";

const columns: IColumn<IPaymentFile>[] = [
  {
    field: "file.originalname",
    label: "Файл",
    render(record, value) {
      return (
        <Typography component={Link} href={record.url} fontWeight={500}>
          {value}
        </Typography>
      );
    },
  },
  {
    field: "createdAt.formatted",
    label: "Дата загрузки",
  },
  {
    field: "inserted",
    label: "Добавлено",
  },
  {
    field: "updated",
    label: "Обновлено",
  },
  {
    field: "total",
    label: "Всего",
  },
  {
    field: "url",
    label: "Файл",
    render(record, value) {
      return (
        <Typography component={Link} href={value} fontWeight={500}>
          Скачать
        </Typography>
      );
    },
  },
];

export default observer(function CompaniesPage() {
  return (
    <Box>
      <PaymentsTabs onUploadSuccess={PaymentsFilesController.list.refresh} />
      <Grid container mb={2} alignItems="center">
        <Grid item xs={8} display={"flex"}>
          <Typography variant="h1">Документы</Typography>

          <IconButton
            size="large"
            onClick={PaymentsFilesController.list.refresh}
          >
            <Refresh color="primary" fontSize="large" />
          </IconButton>
        </Grid>
        {/* <Grid item xs={4} display="flex" justifyContent="flex-end">
          <Button onClick={PaymentsFilesController.add} startIcon={<AddCircle />}>
            Добавить компанию
          </Button>
        </Grid> */}
      </Grid>
      <AppTable<IPaymentFile>
        list={PaymentsFilesController.list}
        columns={columns}
      />
    </Box>
  );
});
