import { Instance, SnapshotIn, types } from "mobx-state-tree";
import { BaseModel } from "../base/base-model";
import { NULLABLE_STRING } from "../extensions/mobx-types";

export const CountryGroupModel = BaseModel.named("CountryGroup").props({
  from: NULLABLE_STRING,
  to: NULLABLE_STRING,
  regionFrom: NULLABLE_STRING,
  regionTo: NULLABLE_STRING,
  bitrixCountryGroupId: types.string,
  bitrixCountryGroupName: types.string,
});

export interface ICountryGroup extends Instance<typeof CountryGroupModel> {}
export interface ICountrySnapshot extends SnapshotIn<typeof CountryGroupModel> {}
