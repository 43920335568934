import { Box, Button, Tab, Tabs } from "@mui/material";
import { AppRouter } from "@src/router/app_router";
import { ApiClient } from "@src/services/api_client";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { DashboardController } from "../dashboard.controller";
import { toast } from "react-toastify";
import UploadFileIcon from "@mui/icons-material/UploadFile";

interface Props {
  onUploadSuccess: () => void;
}

export default observer(function PaymentsTab({ onUploadSuccess }: Props) {
  const onChange = useCallback((_: any, tab: string) => {
    AppRouter.navigate(tab);
  }, []);

  const uploadFile = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.length) {
        try {
          DashboardController.setLoading(true);
          const data = new FormData();
          const file = e.target.files[0];
          data.append("file", file);
          await ApiClient.post("/payments-files", data);
          toast.success("Успешно обработано");
          onUploadSuccess();
        } catch (error) {
          toast.error("Ошибка обработки файла, проверьте формат файла");
        } finally {
          DashboardController.setLoading(false);
          e.target.value = "";
        }
      }
    },
    [onUploadSuccess]
  );

  const location = useLocation();

  return (
    <Box display={"flex"} flexDirection={"row"} justifyContent="space-between" mb={2}>
      <Tabs value={location.pathname} onChange={onChange}>
        <Tab label="Счета" value={"/payments"} />
        <Tab label="Документы" value={"/payments-files"} />
      </Tabs>
      <Button startIcon={<UploadFileIcon />} variant="contained" component="label">
        Загрузить документ
        <input
          hidden
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          type="file"
          onChange={uploadFile}
        />
      </Button>
    </Box>
  );
});
