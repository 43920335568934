import { PaymentsFilesListModel } from "@src/models/payment-file/payments-list.model";
import { types } from "mobx-state-tree";

const PaymentsFilesControllerModel = types.model(
  "PaymentsFilesControllerModel",
  {
    list: types.optional(PaymentsFilesListModel, { url: "/payments-files" }),
  }
);

export const PaymentsFilesController = PaymentsFilesControllerModel.create();
