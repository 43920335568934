import { Box, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import AppFormikSelect from "@src/components/AppFormikSelect";
import AppSelect from "@src/components/AppSelect";
import { IBaseDictionaryItem } from "@src/models/base/base-dictionary-item.model";
import { CountryRegionList } from "@src/models/country/country.model";
import { useFormikContext } from "formik";

import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { CountryGroupForm } from "./country-group-form-drawer";

interface Props {
  countryFieldName: "to" | "from";
  regionFieldName: "regionTo" | "regionFrom";

  label: string;
}

type Direction = "region" | "country";

export default observer(function ({ label, countryFieldName, regionFieldName }: Props) {
  const [direction, setDirection] = useState<Direction>("country");
  const { setFieldValue } = useFormikContext<CountryGroupForm>();
  const onChange = (_: any, v: Direction | null) => {
    if (v) {
      setFieldValue(countryFieldName, null);
      setFieldValue(regionFieldName, null);

      setDirection(v);
    }
  };
  return (
    <Box mb={2}>
      <Box display={"flex"} alignItems={"center"}>
        <Typography mr={2}>{label}</Typography>
        <ToggleButtonGroup size="small" exclusive value={direction} onChange={onChange}>
          <ToggleButton value={"country"}>Страна</ToggleButton>
          <ToggleButton value={"region"}>Регион</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {direction === "country" ? (
        <AppFormikSelect<IBaseDictionaryItem>
          valueField="name"
          url="/countries"
          label="Страна"
          name={countryFieldName}
        />
      ) : (
        <AppSelect items={CountryRegionList} label="Регион" name={regionFieldName} />
      )}
    </Box>
  );
});
