import { RequestsStatsModel } from "@src/models/request/requests-stats.model";
import { RequestsListModel } from "@src/models/request/requset.list.model";
import { ApiClient } from "@src/services/api_client";
import { cast, flow, types } from "mobx-state-tree";

const RequestsControllerModel = types
  .model("RequestsController", {
    list: types.optional(RequestsListModel, { url: "/requests" }),
    stats: types.optional(RequestsStatsModel, {
      requests: 0,
      requestsComplete: 0,
      requestsFail: 0,
      transportations: 0,
      transportationsArrived: 0,
      transportationsPaid: 0,
    }),
  })
  .actions((self) => {
    let statsParams = {};
    let fetchStats = flow(function* (params: object) {
      try {
        statsParams = params;
        const { data } = yield ApiClient.get("/requests/stats", { params: statsParams });
        self.stats = cast(data);
      } catch (error) {}
    });
    return {
      fetchStats,
      refresh: () => {
        self.list.refresh();
        fetchStats(statsParams);
      },
    };
  });

export const RequestsController = RequestsControllerModel.create();
