import { AdminModel, IAdminSnapshopIn } from "@src/models/admin/admin.model";
import { ApiClient } from "@src/services/api_client";

import { cast, flow, types } from "mobx-state-tree";

import { AppRouter } from "../../router/app_router";

const DashboardControllerModel = types
  .model("DashboardControllerModel", {
    isDrawerOpen: true,
    me: types.maybeNull(AdminModel),
    loading: false,
  })
  .actions((self) => ({
    setLoading: (value: boolean) => {
      self.loading = value;
    },
    toggleDrawer: () => {
      self.isDrawerOpen = !self.isDrawerOpen;
    },
    logout: () => {
      localStorage.clear();
      AppRouter.navigate("/login", { replace: true });
    },
    setMe(user: IAdminSnapshopIn) {
      self.me = cast(user);
    },
    getMe: flow(function* getMe() {
      try {
        const { data } = yield ApiClient.get("/users/me");
        self.me = cast(data);
      } catch (error) {}
    }),
  }));

export const DashboardController = DashboardControllerModel.create();
