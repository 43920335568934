import { types } from "mobx-state-tree";

export const BaseDialogModel = types
  .model("BaseDialog")
  .props({
    isOpen: false,
  })
  .actions((self) => ({
    open: () => {
      self.isOpen = true;
    },
    close: () => {
      self.isOpen = false;
    },
    onOk: () => {
      self.isOpen = false;
    },
    onCancel: () => {
      self.isOpen = false;
    },
  }));
