import { Divider, Drawer, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import "yup-phone";
import * as yup from "yup";
import { Form, Formik } from "formik";

import { LoadingButton } from "@mui/lab";

import AppInput from "@src/components/AppInput";

import { IBaseDictionaryController } from "@src/models/base/base-dictionary-controller";

interface Props {
  controller: IBaseDictionaryController;
}

export interface IDictForm {
  id?: string;
  name: string;
  enabled: boolean;
}

const FormSchema = yup.object({
  id: yup.string(),
  name: yup.string().required(),
  enabled: yup.boolean().notRequired(),
});

export default observer(function DictFormDrawer({ controller }: Props) {
  const [loading, setLoading] = useState(false);
  const submit = async (values: IDictForm) => {
    try {
      setLoading(true);
      await controller.onSave(values);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Drawer
      anchor="right"
      open={controller.isOpenForm}
      onClose={controller.toggleForm}
      PaperProps={{
        sx: {
          backgroundColor: "#fff",
          p: 3,

          width: 600,
          boxSizing: "border-box",
        },
      }}
    >
      <Typography variant="h2" mb={1}>
        {controller.name}
      </Typography>
      <Divider />
      <Formik<IDictForm>
        initialValues={{
          id: controller.item?.id,
          name: controller.item?.name ?? "",
          enabled: controller.item?.enabled ?? true,
        }}
        validationSchema={FormSchema}
        onSubmit={submit}
      >
        {({ handleChange, isValid, submitForm, values, isInitialValid }) => (
          <Form autoComplete="off" aria-autocomplete="none">
            <AppInput
              label="Название"
              name="name"
              autoComplete="off"
              aria-autocomplete="none"
              inputProps={{ "aria-autocomplete": "none" }}
            />

            <LoadingButton
              fullWidth
              loading={loading}
              disabled={!isValid}
              onClick={submitForm}
              variant="contained"
              sx={{ mt: 1 }}
            >
              Сохранить
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
});
