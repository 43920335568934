import { Box, Divider, Drawer, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { Form, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { ApiClient } from "../../../../services/api_client";
import { toast } from "react-toastify";
import AppAutocomplete from "@src/components/AppAutocomplete";
import CompanyFormType from "./company-form-type";
import AppInput from "@src/components/AppInput";
import { companiesController } from "../companies.controller";
export interface IBitrixCompanyDto {
  ID: string;
  TITLE: string;
  /** Бин */
  UF_CRM_1609833839575: string | null;
}

interface CompanyForm {
  bitrixId: string | null;
  name: string | null;
  bin: string | null;
}

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

export default observer(function CompanyFormDrawer({
  open,

  onClose,
  onSave,
}: Props) {
  const [loading, setLoading] = useState(false);
  const submit = async (values: CompanyForm) => {
    try {
      setLoading(true);
      if (companiesController.item) {
        await ApiClient.put(`/companies/${companiesController.item.id}`, values);
      } else {
        await ApiClient.post("/companies", values);
      }
      onSave();
      onClose();
      toast.success("Успешно!");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const [type, setType] = useState("bitrix");
  useEffect(() => {
    if (open) {
      setType(companiesController.item == null ? "bitrix" : "self");
    }
  }, [open]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      keepMounted={false}
      PaperProps={{
        sx: {
          backgroundColor: "#fff",
          p: 3,
          maxWidth: 600,
          minWidth: 600,
          boxSizing: "border-box",
        },
      }}
    >
      <Typography variant="h2" mb={1}>
        Компания
      </Typography>
      <Divider />

      <Formik<CompanyForm>
        initialValues={{
          bitrixId: companiesController.item?.bitrixId ?? null,
          bin: companiesController.item?.bin ?? null,
          name: companiesController.item?.name ?? null,
        }}
        onSubmit={submit}
      >
        <Form>
          <CompanyFormType onChangeType={setType} type={type} />
          {type === "bitrix" ? (
            <AppAutocomplete<IBitrixCompanyDto>
              url="/companies/bin"
              titleField="TITLE"
              valueField="ID"
              searchField="UF_CRM_1609833839575"
              label="Поиск по БИН"
              placeholder="Введите БИН компании"
              name="bitrixId"
              requestItemsKey=""
              required
              renderOption={(p, o) => (
                <li {...p} key={`${o.TITLE}_${o.ID}`}>
                  <Box>
                    <Typography>{o.TITLE}</Typography>
                    <Typography color={"grey.900"} fontSize={12}>
                      {o.UF_CRM_1609833839575}
                    </Typography>
                  </Box>
                </li>
              )}
              getOptionLabel={(o) => (o ? `${o.TITLE} (${o.UF_CRM_1609833839575})` : "")}
            />
          ) : (
            <>
              <AppInput required name="name" label="Название" />
              <AppInput required name="bin" label="БИН" />
            </>
          )}

          <LoadingButton fullWidth type="submit" loading={loading} variant="contained" sx={{ mt: 1 }}>
            Добавить
          </LoadingButton>
        </Form>
      </Formik>
    </Drawer>
  );
});
