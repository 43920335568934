import { FormControl, FormHelperText, InputLabel, InputProps, OutlinedInput } from "@mui/material";
import { useFormikContext } from "formik";

import { useCallback } from "react";

import ReactPhoneInput from "react-phone-input-material-ui";
import "react-phone-input-material-ui/lib/style.css";

interface Props extends InputProps {
  name?: string;
  label?: string | null;
  placeholder?: string;
}

export default function AppPhoneInput({
  name = "phone",
  label = "Телефон",
  placeholder = "+0 000 000 00 00",
  autoComplete = "tel",
  inputMode = "tel",
  ref,
  ...props
}: Props) {
  const { handleBlur, getFieldMeta, setFieldValue } = useFormikContext<any>();

  const meta = getFieldMeta<string | null>(name);
  const value = meta.value ?? "";
  const error = meta.error;
  const touched = meta.touched;
  const id = `${name}-helder-text`;
  const _handleChange = useCallback(
    (v: string, data = {}, e: any, fv: string) => {
      setFieldValue(name, v ? `+${v}` : null);
    },
    [name, setFieldValue]
  );

  return (
    <FormControl
      fullWidth
      error={touched && Boolean(error)}
      required={props.required}
      margin="none"
      disabled={props.disabled}
      sx={{
        fieldset: {
          top: 0,
          legend: {
            display: "none",
          },
        },
        ".flag-dropdown": {
          pl: "10px",
        },
      }}
    >
      {label && (
        <InputLabel
          shrink
          htmlFor={name}
          sx={{
            position: "relative",
            whiteSpace: "normal",
            mb: 1,
          }}
        >
          {label}
        </InputLabel>
      )}
      <ReactPhoneInput
        enableAreaCodeStretch={false}
        disableDropdown
        onBlur={handleBlur}
        component={OutlinedInput}
        enableLongNumbers={false}
        value={value}
        onChange={_handleChange}
        inputStyle={{
          paddingLeft: "5px",
        }}
        inputProps={{
          name,
          id,
        }}
        country={"kk"}
      />

      {/* @ts-ignore */}
      {touched && !!error && <FormHelperText id={id}>{error}</FormHelperText>}
    </FormControl>
  );
}
