import { Instance, SnapshotIn, types } from "mobx-state-tree";
import { BaseModel } from "../base/base-model";
import { NULLABLE_STRING } from "../extensions/mobx-types";
export const CompanyModel = types.compose(
  BaseModel.props({ id: types.maybe(types.identifier) }),
  types.model().props({
    name: types.string,
    bin: types.string,
    bitrixId: NULLABLE_STRING,
  })
);

export interface ICompany extends Instance<typeof CompanyModel> {}
export interface ICompanySnapshot extends SnapshotIn<typeof CompanyModel> {}
