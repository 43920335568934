import { AppLoadingController } from "@src/components/AppLoading";
import { IColumnAction } from "@src/components/AppTable";
import { confirmDialogContoller } from "@src/dialogs/confirm/confirm-dialog";
import { ApiClient } from "@src/services/api_client";
import { applySnapshot, flow, Instance, SnapshotIn, types } from "mobx-state-tree";
import { toast } from "react-toastify";
import { BaseDictionaryItemModel, IBaseDictionaryItem } from "./base-dictionary-item.model";
import { BaseListModel } from "./base-list-model";

const BaseDictionaryList = BaseListModel(BaseDictionaryItemModel).named("BaseDictionaryList");

export const BaseDictionaryControllerModel = types
  .model("BaseDictionaryController", {
    list: types.optional(BaseDictionaryList, {}),
    isOpenForm: false,
    item: types.maybeNull(types.reference(BaseDictionaryItemModel)),
    name: "",
    loading: false,
  })
  .actions((self) => {
    const toggleForm = () => {
      if (self.isOpenForm) {
        self.item = null;
      }
      self.isOpenForm = !self.isOpenForm;
    };
    return {
      toggleForm,
      add() {
        self.item = null;
        self.isOpenForm = !self.isOpenForm;
      },
      async onAction(action: IColumnAction, row: IBaseDictionaryItem) {
        switch (action) {
          case "delete":
            confirmDialogContoller.open(
              async () => {
                try {
                  AppLoadingController.show();

                  await ApiClient.delete(`${self.list.url}/${row.id}`);
                  await self.list.refresh();
                  toast.success("Удалено");
                } catch (error) {
                } finally {
                  AppLoadingController.hide();
                }
              },
              "Удаление",
              `Вы уверены что хотите удалить ${row.name}?`
            );
            break;
          case "edit":
            self.item = row;
            self.isOpenForm = true;
            break;
        }
      },
      onSave: flow(function* onSave(values: SnapshotIn<typeof BaseDictionaryItemModel>) {
        try {
          self.loading = true;
          if (self.item) {
            const { data } = yield ApiClient.put(`${self.list.url}/${self.item.id}`, values);
            applySnapshot(self.item, data);
          } else {
            yield ApiClient.post(self.list.url, values);
            yield self.list.refresh();
          }
          toast.success("Сохранено");
          toggleForm();
        } catch (error) {
        } finally {
          self.loading = false;
        }
      }),
    };
  });

export interface IBaseDictionaryController extends Instance<typeof BaseDictionaryControllerModel> {}
