import { AddCircle, Refresh } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import AppTable, { IColumn } from "../../../components/AppTable";

import CompanyFormDrawer from "./components/country-form-drawer";
import { CountriesController } from "./countries.controller";
import { ICountry } from "@src/models/country/country.model";

const columns: IColumn<ICountry>[] = [
  {
    field: "name",
    label: "Название",
  },
  {
    field: "region",
    label: "Регион",
  },
];

export default observer(function CompaniesPage() {
  return (
    <Box>
      <Grid container mb={2} alignItems="center">
        <Grid item xs={8} display={"flex"}>
          <Typography variant="h1">Страны</Typography>
          <IconButton size="large" onClick={CountriesController.list.refresh}>
            <Refresh color="primary" fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <Button onClick={CountriesController.add} startIcon={<AddCircle />}>
            Добавить страну
          </Button>
        </Grid>
      </Grid>
      <AppTable<ICountry>
        list={CountriesController.list}
        columns={columns}
        actions={["delete"]}
        onAction={CountriesController.onAction}
      />
      <CompanyFormDrawer
        open={CountriesController.isOpenForm}
        onClose={CountriesController.toggleForm}
        onSave={CountriesController.list.refresh}
      />
    </Box>
  );
});
