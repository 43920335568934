import { Card, CardContent, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";

interface Props {
  label: string;
  count: number;
}

export default observer(function ({ label, count }: Props) {
  return (
    <Card>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {label}
        </Typography>
        <Typography variant="h5" component="div">
          {count}
        </Typography>
      </CardContent>
    </Card>
  );
});
