import { ISelectItem } from "@src/components/AppSelect";

export enum RequestStageEnum {
  created = "created",
  /** Поступило КП */
  offer = "offer",

  /** Принято КП Заполнена ТЗ */
  fill_transport_request = "fill_transport_request",
  /** Принято КП */
  accepted_offer = "accepted_offer",
  /** Перевозка подтверждена  */
  complete = "complete",

  /** Перевозка в пути  */
  transportation = "transportation",

  /** Завершена (не оплачено) */
  arrived = "arrived",

  /** Завершена (оплачен) */
  paid = "paid",

  /** Сделка проиграна */
  fail = "fail",
}

export const RequestStageDict: Record<
  RequestStageEnum,
  { name: string; color: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" }
> = {
  [RequestStageEnum.created]: {
    name: "Ожидание КП",
    color: "default",
  },
  [RequestStageEnum.offer]: {
    name: "Поступило КП",
    color: "primary",
  },
  [RequestStageEnum.fill_transport_request]: {
    name: "Принято КП. Ожидает ТЗ",
    color: "secondary",
  },
  [RequestStageEnum.accepted_offer]: {
    name: "Принято КП",
    color: "secondary",
  },
  [RequestStageEnum.complete]: {
    name: "Перевозка подтверждена",
    color: "success",
  },
  [RequestStageEnum.transportation]: {
    name: "Перевозка",
    color: "info",
  },
  [RequestStageEnum.arrived]: {
    name: "Завершена (не оплачено)",
    color: "warning",
  },
  [RequestStageEnum.paid]: {
    name: "Завершено (оплачено)",
    color: "success",
  },
  [RequestStageEnum.fail]: {
    name: "Сделка провалена",
    color: "error",
  },
};

export const RequestStageSelectItems: ISelectItem[] = Object.entries(RequestStageDict).map((x) => ({
  label: x[1].name,
  value: x[0],
}));
