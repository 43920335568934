import { Instance, types, } from "mobx-state-tree";
import {
  DateModel,
  NULLABLE_STRING,
  OPTIONAL_BOOLEAN,
} from "../extensions/mobx-types";

export const BaseModel = types.model("Base").props({
  id: NULLABLE_STRING,
  createdAt: DateModel(),
  updatedAt: DateModel(),
  isDeleted: OPTIONAL_BOOLEAN,
});

export interface IBaseModel extends Instance<typeof BaseModel> {}
