import { BaseDictionaryControllerModel } from "@src/models/base/base-dictionary-controller";
import ListAltIcon from "@mui/icons-material/ListAlt";

const dictionaries: Record<string, string> = {
  "request-delivery-condition": "Условия поставки",
  "request-package": "Типы упаковки",
  // "request-loading-type": "Виды погрузки",
};
export const dictionariesMenus = Array.from(
  Object.entries(dictionaries).map(([to, label]) => ({
    to: `/dictionaries/${to}`,
    label,
    icon: <ListAltIcon />,
  }))
);

export default function dictionary_router_loader({ params }: any) {
  const { url } = params;
  const name: string | undefined = dictionaries[url];
  return BaseDictionaryControllerModel.create({ name, list: { url } });
}
