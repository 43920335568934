import { cast, flow, types, IAnyModelType } from "mobx-state-tree";
import { ApiClient } from "../../services/api_client";

export const BaseListModel = <T extends IAnyModelType>(model: T) =>
  types
    .model("BaseListModel", {
      url: "",
      items: types.array(model),
      count: 0,
      rowsPerPage: 10,
      page: 0,
      loading: true,
    })
    .actions((self) => {
      let _params = {};
      const fetch = flow(function* (params) {
        try {
          self.loading = true;
          _params = { page: 1, limit: 10, ...params };
          const { data } = yield ApiClient.get(self.url, {
            params: _params,
          });
          self.items = cast(data.docs);
          self.count = data.totalDocs;
          self.page = data.page - 1;
        } catch (error) {
          console.log(error);
        } finally {
          self.loading = false;
        }
      });
      const refresh = () => {
        return fetch(_params);
      };
      return {
        fetch,
        refresh,
      };
    });

export interface IBaseList<T> {
  url: string;
  items: T[];
  count: number;
  rowsPerPage: number;
  page: number;
  loading: boolean;
  fetch: (params: any) => Promise<void>;
  refresh: () => Promise<void>;
} //extends Instance<typeof BaseListModel> {}
