import { List } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import MenuItem from "./MenuItem";
import PaymentsIcon from "@mui/icons-material/Payments";
import PublicIcon from "@mui/icons-material/Public";
import RouteIcon from "@mui/icons-material/Route";
import { dictionariesMenus } from "../dictionaries/dictionary_router_loader";
import RequestIcon from "@src/icons/RequestIcon";
import ListAltIcon from "@mui/icons-material/ListAlt";

const MENUS = [
  {
    label: "Запросы",
    icon: <RequestIcon />,
    to: "requests",
  },
  {
    label: "Компании",
    icon: <BusinessIcon />,
    to: "companies",
  },
  {
    label: "Пользователи",
    icon: <PeopleIcon />,
    to: "users",
  },
  {
    label: "Счета на оплату",
    icon: <PaymentsIcon />,
    to: "payments",
  },
  {
    label: "Страны",
    icon: <PublicIcon />,
    to: "countries",
  },

  {
    label: "Группы агентов",
    icon: <RouteIcon />,
    to: "country-groups",
  },
  {
    label: "Тип ТПС",
    icon: <ListAltIcon />,
    to: "dictionaries/request-tranport",
  },
  ...dictionariesMenus,
];

export default function Menus() {
  return (
    <List>
      {MENUS.map((props, index) => (
        <MenuItem key={props.label} {...props} />
      ))}
    </List>
  );
}
