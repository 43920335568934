import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderOptionState,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import { useFormikContext } from "formik";

import React, { useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { ApiClient } from "../services/api_client";
import * as objectPath from "object-path";
import { observer } from "mobx-react-lite";

export interface IAutocompleteSelectItem {
  label: string;
  value: any;
}

interface Props<T> {
  name: string;
  label: string;
  placeholder?: string;
  url: string;
  titleField: string;
  valueField: string;
  searchField?: string;
  required?: boolean;
  onSelect?: (item: T | null) => void;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode | undefined;
  requestItemsKey?: string;
  getOptionLabel?: (option: T) => string;
}

export default observer(function AppAutocomplete<T extends {}>({
  name,
  label,
  placeholder = "Введите значние для поиска",
  url,
  titleField,
  valueField,
  onSelect,
  searchField,
  requestItemsKey = "docs",
  required,
  ...props
}: Props<T>) {
  const { getFieldMeta, setFieldValue, handleBlur } = useFormikContext<any>();
  const meta = getFieldMeta<T | null>(name);
  const fieldValue = meta.value ?? null;
  const error = meta.error;
  const touched = meta.touched;
  const id = `${name}-helder-text`;
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<T[]>([]);
  const [search, setSearch] = useState(``);
  const searchText = useDebounce<string>(search, 500);

  const handleChangeInput = (value: string) => {
    setSearch(value);
  };
  const _onSelect = (
    event: React.SyntheticEvent<Element, Event>,
    value: T | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<{}> | undefined
  ) => {
    const _value = objectPath.get(value ?? {}, valueField, "");

    setFieldValue(name, _value);
    setSelected(value);
    onSelect && onSelect(value);
  };

  useEffect(() => {
    async function fetch() {
      try {
        setLoading(true);
        let params: any = { searchText };
        if (String(fieldValue) === searchText && searchText.length > 0) {
          return;
        }
        const { data } = await ApiClient.get(url, {
          params: { ...params, limit: 40 },
        });
        const docs: any[] = objectPath.get(data, requestItemsKey, []);

        setOptions(docs);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, url]);

  const [selected, setSelected] = useState(fieldValue ?? null);

  return (
    <FormControl fullWidth error={touched && Boolean(error)} variant="standard" margin="dense" required={required}>
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <Autocomplete<T>
        autoComplete={true}
        options={options}
        loading={loading}
        onChange={_onSelect}
        onBlur={handleBlur}
        noOptionsText={"Ничего не найдено"}
        getOptionLabel={(option) => {
          const op = (options.find((o) => objectPath.get(o, valueField) === option) as T) ?? option;

          return objectPath.get(option, titleField) || objectPath.get(op, titleField) || option;
        }}
        isOptionEqualToValue={(option: any, val: any) => {
          return objectPath.get(option, valueField) === objectPath.get(val, valueField);
        }}
        onInputChange={(event, newInputValue) => {
          handleChangeInput(newInputValue);
        }}
        value={selected}
        renderOption={(p, o) => (
          <li {...p} key={`${objectPath.get(o, titleField, "")}_${objectPath.get(o, valueField, "")}`}>
            {objectPath.get(o, titleField, "")}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            autoComplete="off"
            type="search"
            variant="outlined"
            required={required}
            InputProps={{
              ...params.InputProps,
              autoComplete: "off",
              inputMode: "text",
              type: "text",
              placeholder: placeholder,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        id={name}
        aria-describedby={id}
        placeholder={placeholder || label}
        {...props}
      />
      {/* @ts-ignore */}
      {touched && !!error && <FormHelperText id={id}>{error}</FormHelperText>}
    </FormControl>
  );
});
