import { ISelectItem } from "@src/components/AppSelect";
import { types } from "mobx-state-tree";

export enum BitrixTypeIdEnum {
  /** FTL Автоперевозка */
  avto = "COMPLEX",
  /** 'Авиаперевозка', */
  avia = "SALE",
  /** Мультимодальная */
  multimodal = "GOODS",
  /** Железнодорожная перевозка (вагонная) */
  zhdvagon = "SERVICES",
  /** LTL Автоперевозка */
  consol = "1",
}

export const BitrixTypeIdDict: Record<
  BitrixTypeIdEnum,
  { title: string; description: string; image: string; subtitle?: string }
> = {
  [BitrixTypeIdEnum.avto]: {
    title: "Автоперевозка",
    description: "Все виды грузов от двери до двери",
    image: `/images/request-types/avto.png`,
  },
  [BitrixTypeIdEnum.avia]: {
    title: "Авиаперевозка",
    description: "Самый быстрый способ доставки",
    image: `/images/request-types/avia.png`,
  },
  [BitrixTypeIdEnum.zhdvagon]: {
    title: "Железнодорожная",
    subtitle: "Вагонная",
    description: "Популярный и наиболее экономичный",
    image: `/images/request-types/vagon.png`,
  },
  [BitrixTypeIdEnum.multimodal]: {
    title: "Мультимодальная",
    subtitle: "Контейнерная",
    description: "Сокращение времени доставки и стоимости",
    image: `/images/request-types/multimodal.png`,
  },
  [BitrixTypeIdEnum.consol]: {
    title: "Консолидированная",
    description: "Оперативность и надежность",
    image: `/images/request-types/consol.png`,
  },
} as const;

export const BitrixTypeIdList = Object.entries(BitrixTypeIdDict).map(([type, props]) => ({
  type,
  ...props,
}));
export const BitrixTypeIdSelectItems: ISelectItem[] = BitrixTypeIdList.map((x) => ({
  label: x.title,
  value: x.type,
}));
export const BitrixTypeIdEnumArray = Object.values(BitrixTypeIdEnum);

export const BitrixTypeIdModel = types.enumeration<BitrixTypeIdEnum>(BitrixTypeIdEnumArray);
