import { SvgIcon } from "@mui/material";
import React from "react";

export default function ArrowDownIcon() {
  return (
    <SvgIcon
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="#14467C"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1251_2883)">
        <path
          d="M15.0441 11.6831C14.9744 11.6115 14.8787 11.5714 14.7788 11.5717H12.8327C12.6809 11.5717 12.5578 11.4487 12.5578 11.2969V5.21098C12.5578 5.00755 12.3929 4.84265 12.1894 4.84265H8.40157C8.19814 4.84265 8.03324 5.00755 8.03324 5.21098V11.2969C8.03324 11.4487 7.91016 11.5717 7.75835 11.5717H5.8067C5.6033 11.5679 5.4353 11.7297 5.43146 11.933C5.42953 12.0349 5.46986 12.1329 5.54282 12.204L10.0289 16.69C10.0971 16.7593 10.19 16.7989 10.2873 16.8C10.3865 16.8003 10.4816 16.7607 10.5512 16.69L15.0372 12.204C15.183 12.062 15.186 11.8289 15.0441 11.6831Z"
          fill="#14467C"
        />
      </g>
      <defs>
        <clipPath id="clip0_1251_2883">
          <rect
            width="13.02"
            height="13.02"
            transform="translate(3.78003 3.78003)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
