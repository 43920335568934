import { Box, CircularProgress, CssBaseline, Dialog } from "@mui/material";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Drawer from "./components/SideDrawer";
import Header from "./components/Header";
import ConfirmDialog from "../../dialogs/confirm/confirm-dialog";
import { DashboardController } from "./dashboard.controller";
import { observer } from "mobx-react-lite";

const DashboardPage: React.FC = () => {
  useEffect(() => {
    DashboardController.getMe();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer />

      <Header />
      <Box component="main" sx={{ flexGrow: 1, p: 2, marginTop: "65px" }}>
        <Outlet />
      </Box>
      <ConfirmDialog />
      <Dialog
        PaperProps={{
          sx: {
            backgroundColor: "transparent",
            boxShadow: "none",
            color: "#fff",
          },
        }}
        open={DashboardController.loading}
      >
        <CircularProgress color="inherit" />
      </Dialog>
    </Box>
  );
};

export default observer(DashboardPage);
