import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import { ApiClient } from "@src/services/api_client";
import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { GroupBase, OptionsOrGroups, SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import * as objectPath from "object-path";

interface Props<T> {
  label: string;
  name: string;
  defaultValue?: T | null;
  url?: string;
  valueField?: string;
  labelField?: string;
  responseField?: string;
  required?: boolean;
  placeholder?: string;
  onSelect?: (value: T, name: string) => void;
}

function AppFormikSelect<T extends {}>({
  label,
  name,
  labelField = "name",
  required,
  valueField = "id",
  placeholder,
  url,
  defaultValue,
  onSelect,
}: Props<T>) {
  const { getFieldMeta, setFieldValue } = useFormikContext<any>();
  const meta = getFieldMeta(name);
  const error = meta.error;
  const touched = meta.touched;
  const id = `${name}-helder-text`;
  const [seletedItem, setSeletedItem] = useState<T | null>(null);

  useEffect(() => {
    if (meta.value == null) {
      setSeletedItem(null);
      console.log(meta.value);
    }
  }, [meta.value]);

  useEffect(() => {
    async function fetchInitial() {
      try {
        const { data } = await ApiClient.get<{ docs: T[] }>(url!, {
          params: { [valueField]: meta.value },
        });
        setSeletedItem(data.docs[0]);
      } catch {}
    }
    if (meta.value) {
      fetchInitial();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadOptions = useCallback(
    async (inputValue: string, _: any): Promise<OptionsOrGroups<T, GroupBase<T>>> => {
      try {
        const { data } = await ApiClient.get<{ docs: T[] }>(url!, {
          params: { searchText: inputValue },
        });

        return data.docs;
      } catch (error) {
        return [];
      }
    },
    [url]
  );
  const onChange = useCallback(
    (newValue: SingleValue<T>) => {
      setFieldValue(name, newValue ? objectPath.get(newValue, valueField) : null);
      onSelect?.(newValue as T, name);
      setSeletedItem(newValue);
    },
    [name, setFieldValue, valueField, onSelect]
  );

  const getOptionValue = useCallback(
    (option: T) => {
      return objectPath.get(option, valueField);
    },
    [valueField]
  );
  const getOptionLabel = useCallback(
    (option: T) => {
      return objectPath.get(option, labelField);
    },
    [labelField]
  );

  return (
    <FormControl fullWidth error={touched && Boolean(error)} variant="outlined" margin="none" required={required}>
      <InputLabel
        shrink
        htmlFor={name}
        sx={{
          position: "relative",
        }}
      >
        {label}
      </InputLabel>
      <AsyncSelect<T>
        id={name}
        isClearable

        noOptionsMessage={(v) => "Ничего не найдено. Уточните поиск"}
        loadingMessage={(v) => "Поиск..."}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#0097be14",
            primary25: "#0097be14",
          },
        })}
        styles={{
          input: (base, props) => ({
            ...base,
          }),
          container: (base, props) => ({
            ...base,
            marginTop: 7,
          }),
          control: (base, props) => ({
            ...base,
            borderRadius: "10px",
            backgroundColor: "#FBFBFB",
            paddingTop: "5px",
            paddingBottom: "5px",
            borderColor: "#E0E5F1",
            ":hover": {
              borderColor: "#000",
            },
            boxShadow: "none",
          }),
          menu: (base, props) => ({
            ...base,
            zIndex: 1000,
          }),

          option: (base, props) => ({
            ...base,

            color: props.isFocused || props.isSelected ? "#000" : base.color,
          }),
        }}
        aria-describedby={id}
        name={name}
        cacheOptions
        placeholder={placeholder ?? "Введите значение для поиска"}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        defaultValue={defaultValue}
        defaultOptions
        value={seletedItem}
        loadOptions={url ? loadOptions : undefined}
        onChange={onChange}
      />
      {/* @ts-ignore */}
      {touched && !!error && <FormHelperText id={id}>{error}</FormHelperText>}
    </FormControl>
  );
}
export default observer(AppFormikSelect);
