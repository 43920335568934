import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  InputProps,
  OutlinedInput,
} from "@mui/material";
import { useFormikContext } from "formik";
import { useState } from "react";

interface Props extends InputProps {
  name?: string;
  label?: string;
  placeholder?: string;
}

export default function AppPasswordInput({
  name = "password",
  label = "Пароль",
  placeholder = "Введите пароль",
  autoComplete = "current-password",
  ...props
}: Props) {
  const { handleChange, handleBlur, getFieldMeta } = useFormikContext<any>();

  const meta = getFieldMeta(name);
  const value = meta.value || "";
  const error = meta.error;
  const touched = meta.touched;
  const id = `${name}-helder-text`;
  const [visibility, setVisibility] = useState(false);
  const togglePasswordVisibility = () => {
    setVisibility(!visibility);
  };

  return (
    <FormControl
      fullWidth
      error={touched && Boolean(error)}
      variant="outlined"
      margin="normal"
      required={props.required}
    >
      {label && (
        <InputLabel
          shrink
          htmlFor={name}
          sx={{
            position: "relative",
            whiteSpace: "normal",
          }}
        >
          {label}
        </InputLabel>
        // <Typography variant="h4" mb={1}>
        //   {label}
        // </Typography>
      )}
      <OutlinedInput
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        id={name}
        aria-describedby={id}
        name={name}
        placeholder={placeholder || label}
        type={visibility ? "text" : "password"}
        autoComplete={autoComplete}
        endAdornment={
          <InputAdornment position="start">
            <IconButton
              aria-label="toggle password visibility"
              onClick={togglePasswordVisibility}
            >
              {visibility ? (
                <Visibility color="primary" />
              ) : (
                <VisibilityOff color="disabled" />
              )}
            </IconButton>
          </InputAdornment>
        }
        {...props}
      />

      {touched && !!error && <FormHelperText id={id}>{error}</FormHelperText>}
    </FormControl>
  );
}
