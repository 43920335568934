import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik, Form } from "formik";
import { useSearchParams } from "react-router-dom";
import AppFormikSelect from "@src/components/AppFormikSelect";
import { ICompany } from "@src/models/company/company.model";
import AppSelect from "@src/components/AppSelect";
import { RequestStageSelectItems } from "@src/models/request/request-stage.enum";

export default observer(function () {
  const [searchParams, setSearchParams] = useSearchParams();

  const onSubmit = useCallback(
    (values: object) => {
      setSearchParams((p) => {
        Object.entries(values).forEach(([k, v]) => {
          if (v) {
            p.set(k, v as string);
          } else {
            p.delete(k);
          }
        });
        return p;
      });
    },
    [setSearchParams]
  );

  const onReset = () => {
    setSearchParams((p) => {
      p.forEach((v, k) => {
        p.delete(k);
      });
      return p;
    });
  };
  //   const formik = useFormik({
  //     onSubmit: onSubmit,
  //     onReset
  //     initialValues: {
  //       companyId: searchParams.get("companyId"),
  //     },
  //   });

  return (
    <Accordion elevation={1} disableGutters={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography>Фильтр</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Formik
          onSubmit={onSubmit}
          onReset={onReset}
          initialValues={{
            companyId: searchParams.get("companyId"),
            stage: searchParams.get("stage"),
          }}
        >
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <AppFormikSelect<ICompany> url="/companies" label="Компания" name="companyId" />
              </Grid>
              <Grid item xs={12} md={4}>
                <AppSelect items={RequestStageSelectItems} name="stage" label="Этап" />
              </Grid>
            </Grid>

            <Stack mt={1} direction={"row"} spacing={1}>
              <Button variant="outlined" color="error" type="reset">
                Сбросить
              </Button>
              <Button type="submit">Применить</Button>
            </Stack>
          </Form>
        </Formik>
      </AccordionDetails>
    </Accordion>
  );
});
