import React from "react";
import { Navigate, Outlet } from "react-router-dom";
interface Props {
  children?: React.ReactElement;
}

const AppProtected = ({ children }: Props) => {
  const token = localStorage.getItem("access_token");

  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return children ? children : <Outlet />;
};
export default AppProtected;
