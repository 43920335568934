import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";

interface Props {
  onChangeType: (type: string) => void;
  type: string;
}

export default observer(function ({ type, onChangeType }: Props) {
  const form = useFormikContext();

  const handleType = (_: any, v: string) => {
    if (v === "self") {
      form.setValues({ bitrixId: null });
    } else {
      form.setValues({ name: null, bin: null });
    }
    onChangeType(v);
  };
  return (
    <ToggleButtonGroup
      sx={{ my: 2 }}
      size="small"
      value={type}
      exclusive
      onChange={handleType}
      aria-label="text alignment"
    >
      <ToggleButton value="bitrix" aria-label="left aligned">
        С битрикса
      </ToggleButton>
      <ToggleButton value="self" aria-label="centered">
        В ручную
      </ToggleButton>
    </ToggleButtonGroup>
  );
});
