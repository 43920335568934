import { Instance, types } from "mobx-state-tree";
import { BaseModel } from "./base-model";

export const BaseDictionaryItemModel = BaseModel.named("BaseDictionaryItemModel")
  .props({
    id: types.maybe(types.identifier),
    name: types.string,
    enabled: types.boolean,
  })
  .views((self) => ({
    get enabledText() {
      return self.enabled ? "Да" : "Нет";
    },
  }));

export interface IBaseDictionaryItem extends Instance<typeof BaseDictionaryItemModel> {}
