import { setLocale } from "yup";

setLocale({
  mixed: {
    required: "обязательное поле",
  },
  string: {
    email: "Неверный формат email",
    length(params) {
      return `Должно быть ${params.length} символов`;
    },
  },
});
