import { createTheme } from "@mui/material/styles";

export const ThemeColors = {
  primary: "#0097BE",
  secondary: "#040D17",
  gray50: "#E0E5F1",
  gray90: "#777A8A",
  gray: "#040D17",
  primaryBlue: "#14467C",
  error: "#EB5757",
};
export const ThemeFontFamily = "Inter, sans-serif";

export const MainTheme = createTheme({
  palette: {
    background: {
      default: "#F5F5F5",
    },
    primary: {
      main: ThemeColors.primary,
    },
    secondary: {
      main: ThemeColors.secondary,
    },
    grey: {
      "50": ThemeColors.gray50,
      "900": ThemeColors.gray90,
    },
  },

  typography: {
    fontFamily: ThemeFontFamily,
    h1: {
      fontSize: "40px",
      fontWeight: 700,
      lineHeight: 1.4,
      fontFamily: ThemeFontFamily,
    },
    body1: { fontFamily: ThemeFontFamily },
    h2: {
      fontSize: "30px",
      fontWeight: 700,
      lineHeight: 1.4,
      fontFamily: ThemeFontFamily,
    },
    h4: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderBottomColor: ThemeColors.gray50,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          backgroundColor: "#fff",
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderColor: ThemeColors.gray50,
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: "20px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: 0,
        },
        root: {
          ".MuiOutlinedInput-root .MuiAutocomplete-input": {
            paddingTop: "12px",
            paddingBottom: "12px",
            paddingLeft: "16px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          paddingRight: 0,
          backgroundColor: "#FBFBFB",
        },
        multiline: {
          paddingTop: "0px",
        },
        input: {
          paddingTop: "12px",
          paddingBottom: "12px",
        },

        notchedOutline: {
          borderColor: ThemeColors.gray50,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        contained: {
          boxShadow: "0px 14px 42px -16px #0097BE",
          padding: "10px 14px",
          borderRadius: "10px",
          fontWeight: "700",
          textTransform: "initial",
          minWidth: "180px",
        },
      },
    },

    MuiLink: {
      defaultProps: {
        underline: "none",
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: ThemeColors.secondary,
        },
        root: {
          "& .MuiListItemText-primary": {
            color: "#fff",
            fontSize: 16,
            fontWeight: 700,
          },
          "& .MuiListItem-root": {
            marginBottom: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
            "& .MuiButtonBase-root": {
              borderRadius: "8px",
              "&.Mui-selected": {
                backgroundColor: ThemeColors.primaryBlue,
              },
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "margin-top": "6px",
          "label+.MuiInputBase-root": {
            marginTop: "5px",
            maxWidth: "100%",
          },
          "label+.MuiAutocomplete-root": {
            marginTop: "24px",
          },
          borderRadius: "8px",
        },
      },
      defaultProps: {
        variant: "standard",
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 400,
          color: ThemeColors.gray,
          transform: "none",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 500,
          color: ThemeColors.gray,
          "& .MuiFormLabel-asterisk": {
            color: ThemeColors.error,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderTopRightRadius: "10px",
          borderTopLeftRadius: "10px",
          overflow: "hidden",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: { fontSize: 16, fontWeight: 400 },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderTopRightRadius: "10px",
          borderTopLeftRadius: "10px",

          "& .MuiTableCell-root": {
            backgroundColor: ThemeColors.gray,
            // height: "40px",
            color: "#fff",
            fontSize: 16,
            fontWeight: 500,
            padding: "13px",
            "& .Mui-active": {
              ".MuiTableSortLabel-icon": {
                fill: ThemeColors.primary,
              },
            },
          },
          "& .MuiTableSortLabel-icon": {
            fill: "#fff",
            fontSize: 22,
            width: 22,
            height: 22,
            opacity: 1,
          },
          "& .MuiButtonBase-root.MuiTableSortLabel-root": {
            color: "#fff",
            fontSize: 16,
            fontWeight: 500,
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: "1px solid #FBFBFB;",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: "100%",
          borderRadius: "8px",
          backgroundColor: ThemeColors.primaryBlue,
        },
        flexContainer: {
          position: "relative",
          width: "auto",

          zIndex: 10,

          ".MuiTab-textColorPrimary": {
            color: ThemeColors.primaryBlue,
            fontWeight: 800,
            lineHeight: 1,
            transition: "200ms color",

            "&.Mui-selected": {
              color: "#fff",
            },
          },
        },
        root: {
          width: "auto",
          ".MuiTabs-scroller": {
            width: "auto",
          },
          border: "1px solid #E0E5F1",
          backgroundColor: "#fff",
          borderRadius: "12px",
          padding: "4px",
          ".MuiTab-root": {
            borderRadius: "12px",
            minWidth: "200px",
            minHeight: 40,
          },
          fontSize: 16,
        },
      },
    },
  },
});
