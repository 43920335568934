import { Instance, SnapshotOut, types } from "mobx-state-tree";

import { BaseModel } from "../base/base-model";
import { CompanyModel } from "../company/company.model";
import { DateModel, NULLABLE_BOOLEAN, NULLABLE_NUMBER, NULLABLE_STRING } from "../extensions/mobx-types";
import { PaymentModel } from "../payment/payment.model";
import { UserModel } from "../user/user.model";
import { RequestAddressModel } from "./request-address.model";
import { RequestCurrencyEnum } from "./request-currency.enum";
import { RequestStageDict, RequestStageEnum } from "./request-stage.enum";
import { BitrixTypeIdModel } from "./request-type.enum";

export const RequestModel = BaseModel.named("RequestModel")
  .props({
    type: BitrixTypeIdModel,

    /** Условия поставки */
    deliveryCondition: NULLABLE_STRING,

    /** Города и адреса погрузки  */
    addressesLoading: types.array(RequestAddressModel),

    /** Города и адреса выгрузки  */
    addressesUnloading: types.array(RequestAddressModel),

    /** Наимененование груза  */
    name: NULLABLE_STRING,

    /** Дата погрузки  */
    loadingDate: DateModel(),

    /** Вес м3  */
    weight: NULLABLE_STRING,

    /** Объем м3  */
    volume: NULLABLE_STRING,

    /** Кол-во погрузочный мест  */
    numberOfPackages: NULLABLE_STRING,

    /** Тип упаковки  */
    boxPackage: NULLABLE_STRING,

    /** Штабелировать или нет  */
    stack: NULLABLE_BOOLEAN,

    /** Тип ТПС  */
    transportId: NULLABLE_STRING,

    transportCount: NULLABLE_NUMBER,

    /** ТС Прямые или с перегрузом  */
    transportType: NULLABLE_STRING,

    /** Стоимость груза */
    price: NULLABLE_NUMBER,

    /** Дополнительные характеристики груза: */
    packageDetail: NULLABLE_STRING,

    /** Для опасного груза */
    dangerInfo: NULLABLE_STRING,

    /** Требуется t-режим а */
    temperature: NULLABLE_STRING,

    /** Тип погрузки */
    typeLoadingId: NULLABLE_STRING,

    /** Наименование и адрес СВХ для импортного груза*/
    addressImport: NULLABLE_STRING,

    packageDimensions: NULLABLE_STRING,

    userId: NULLABLE_STRING,

    user: types.maybeNull(UserModel),

    companyId: NULLABLE_STRING,

    company: types.maybeNull(CompanyModel),

    bitrixId: NULLABLE_STRING,

    /** Тип ТПС  */
    transportName: NULLABLE_STRING,
    /** Тип Погрузки  */
    typeLoadingName: NULLABLE_STRING,

    currency: types.maybeNull(types.enumeration<RequestCurrencyEnum>(Object.values(RequestCurrencyEnum))),

    requestOfferId: NULLABLE_STRING,
    stage: types.enumeration<RequestStageEnum>(Object.values(RequestStageEnum)),
    status: NULLABLE_STRING,
    isTransportation: types.boolean,
    referenceNumber: NULLABLE_STRING,
    arrivalDate: DateModel(),
    currentLocation: NULLABLE_STRING,
    transportNumber: NULLABLE_STRING,
    assignedUserName: NULLABLE_STRING,
    assignedUserEmail: NULLABLE_STRING,
    assignedUserPhone: NULLABLE_STRING,

    operativeUserEmail: NULLABLE_STRING,
    operativeUserName: NULLABLE_STRING,
    operativeUserPhone: NULLABLE_STRING,

    invoiceLink: NULLABLE_STRING,
    transportDocumentId: NULLABLE_STRING,
    offerDocumentId: NULLABLE_STRING,
    avrLink: NULLABLE_STRING,

    budget: NULLABLE_NUMBER,
    budgetCurrency: types.maybeNull(types.enumeration<RequestCurrencyEnum>(Object.values(RequestCurrencyEnum))),

    payment: types.maybeNull(PaymentModel),
  })
  .views((self) => ({
    get fullname() {
      const names = [self.name, self.boxPackage, self.deliveryCondition, `${self.weight} кг`, `${self.volume} м3`];
      return names.filter((x) => x?.trim()).join(", ");
    },
    get fullAddressLoading() {
      const address = self.addressesLoading
        .map((x) => {
          let str = `${x.country}, ${x.city}`;
          if (x.address) {
            str += `, ${x.address}`;
          }
          return str;
        })
        .join("-");
      return address;
    },
    get fullAddressUnloading() {
      const address = self.addressesUnloading
        .map((x) => {
          let str = `${x.country}, ${x.city}`;

          if (x.address) {
            str += `, ${x.address}`;
          }
          return str;
        })
        .join("-");
      return address;
    },
    get stageName() {
      return RequestStageDict[self.stage];
    },
  }))
  .actions((self) => ({}));

export interface IRequest extends Instance<typeof RequestModel> {}
export interface IRequestSnapshopOut extends SnapshotOut<typeof RequestModel> {}
