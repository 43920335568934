import { AddCircle, Refresh } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import AppTable, { IColumn } from "../../../components/AppTable";

import FormDrawer from "./components/request-transport-form-drawer";

import { IRequestTransportType } from "@src/models/request-transport/request-transport.model";
import { RequestTransportTypeController } from "./request-transport.controller";

const columns: IColumn<IRequestTransportType>[] = [
  {
    field: "name",
    label: "Название",
  },
  {
    field: "typeName",
    sortKey: "type",
    label: "Тип перевозки",
  },
];

export default observer(function () {
  return (
    <Box>
      <Grid container mb={2} alignItems="center">
        <Grid item xs={8} display={"flex"}>
          <Typography variant="h1">Тип ТПС</Typography>
          <IconButton size="large" onClick={RequestTransportTypeController.list.refresh}>
            <Refresh color="primary" fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <Button onClick={RequestTransportTypeController.add} startIcon={<AddCircle />}>
            Добавить
          </Button>
        </Grid>
      </Grid>
      <AppTable<IRequestTransportType>
        list={RequestTransportTypeController.list}
        columns={columns}
        actions={["edit", "delete"]}
        onAction={RequestTransportTypeController.onAction}
      />
      <FormDrawer
        open={RequestTransportTypeController.isOpenForm}
        onClose={RequestTransportTypeController.toggleForm}
        onSave={RequestTransportTypeController.list.refresh}
      />
    </Box>
  );
});
