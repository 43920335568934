import { types } from "mobx-state-tree";
import { NULLABLE_STRING } from "../extensions/mobx-types";

export enum CountryRegionEnum {
  sng = "СНГ",
  europe = "Европа",
  other = "Другой",
}

export const RequestAddressModel = types.model("RequestAddress", {
  country: types.string,
  city: types.string,
  address: NULLABLE_STRING,
  region: types.maybeNull(types.enumeration<CountryRegionEnum>(Object.values(CountryRegionEnum))),
});

export interface IAddress {
  city: string | null;
  address?: string | null;
  region?: CountryRegionEnum | null;
}
