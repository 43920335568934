import { Instance, SnapshotIn, types } from "mobx-state-tree";
import { BaseModel } from "../base/base-model";
import { ISelectItem } from "@src/components/AppSelect";

export enum CountryRegionEnum {
  sng = "СНГ",
  europe = "Европа",
  other = "Другой",
}

export const CountryRegionList: ISelectItem[] = Object.values(CountryRegionEnum).map((x) => ({ label: x, value: x }));

export const CountryModel = types.compose(
  BaseModel.props({ id: types.maybe(types.identifier) }),
  types.model().props({
    name: types.string,
    region: types.string,
  })
);

export interface ICountry extends Instance<typeof CountryModel> {}
export interface ICountrySnapshot extends SnapshotIn<typeof CountryModel> {}
