import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProp,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";

import { observer } from "mobx-react-lite";
import { styled } from "@mui/material/styles";
import { LogoutOutlined } from "@mui/icons-material";
import { DashboardController } from "../dashboard.controller";

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProp {
  open: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - 65px)`,
  ...(open && {
    // marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default observer(function Header() {
  return (
    <React.Fragment>
      <AppBar open={DashboardController.isDrawerOpen}>
        <Toolbar>
          <Grid container alignItems={"center"} justifyContent="end">
            <Grid item>
              <Typography color="#000">
                {DashboardController.me?.name}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={DashboardController.logout}>
                <LogoutOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
});
