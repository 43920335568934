import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps, Typography } from "@mui/material";
import { useFormikContext } from "formik";

export interface ISelectItem {
  label: string;
  value: any;
  [key: string]: any;
}

interface Props extends SelectProps {
  name: string;
  label: string;
  placeholder?: string;
  items: ISelectItem[];
  labelProp?: string;
  valueProp?: string;
}

export default function AppSelect({
  name,
  label,
  placeholder = "Выберите значение",
  items,
  labelProp = "label",
  valueProp = "value",
  ...props
}: Props) {
  const { handleChange, handleBlur, getFieldMeta } = useFormikContext<any>();
  const meta = getFieldMeta(name);
  const value = meta.value || "";
  const error = meta.error;
  const touched = meta.touched;
  const id = `${name}-helder-text`;

  return (
    <FormControl fullWidth error={touched && Boolean(error)} variant="outlined" margin="none" required={props.required}>
      <InputLabel
        shrink
        htmlFor={name}
        sx={{
          position: "relative",
        }}
      >
        {label}
      </InputLabel>
      <Select
        id={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        aria-describedby={id}
        name={name}
        SelectDisplayProps={{ placeholder: placeholder || label }}
        {...props}
        placeholder={placeholder || label}
        displayEmpty
      >
        <MenuItem disabled value={""}>
          <Typography fontSize={16} color="grey.900">
            {placeholder}
          </Typography>
        </MenuItem>
        {items.map((x) => (
          <MenuItem key={x[valueProp]} value={x[valueProp]}>
            {x[labelProp]}
          </MenuItem>
        ))}
      </Select>
      {/* @ts-ignore */}
      {touched && !!error && <FormHelperText id={id}>{error}</FormHelperText>}
    </FormControl>
  );
}
