import { AddCircle, Delete, Edit, Refresh } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import AppTable, { IColumn } from "../../../components/AppTable";

import { ICompany } from "../../../models/company/company.model";
import { companiesController } from "./companies.controller";

import CompanyFormDrawer from "./components/company-form-drawer";

const columns: IColumn<ICompany>[] = [
  {
    field: "bitrixId",
    label: "ID",
  },
  {
    field: "bin",
    label: "БИН",
  },
  {
    field: "name",
    label: "Название",
  },
  {
    field: "action",
    label: "Действие",
    render: (record, value) => {
      return (
        <Stack direction={"row"} spacing={1}>
          {record.bitrixId == null && (
            <IconButton color="primary" onClick={() => companiesController.onAction("edit", record)}>
              <Edit />
            </IconButton>
          )}
          <IconButton color="error" onClick={() => companiesController.onAction("delete", record)}>
            <Delete />
          </IconButton>
        </Stack>
      );
    },
  },
];

export default observer(function CompaniesPage() {
  return (
    <Box>
      <Grid container mb={2} alignItems="center">
        <Grid item xs={8} display={"flex"}>
          <Typography variant="h1">Компании</Typography>
          <IconButton size="large" onClick={companiesController.list.refresh}>
            <Refresh color="primary" fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <Button onClick={companiesController.add} startIcon={<AddCircle />}>
            Добавить компанию
          </Button>
        </Grid>
      </Grid>
      <AppTable<ICompany> list={companiesController.list} columns={columns} />

      <CompanyFormDrawer
        open={companiesController.isOpenForm}
        onClose={companiesController.toggleForm}
        onSave={companiesController.list.refresh}
      />
    </Box>
  );
});
