import { types } from "mobx-state-tree";

export const RequestsStatsModel = types.model("RequestsStats", {
  transportations: types.number,
  transportationsArrived: types.number,
  transportationsPaid: types.number,

  requests: types.number,
  requestsFail: types.number,
  requestsComplete: types.number,
});
