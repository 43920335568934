import { Instance, SnapshotIn, types } from "mobx-state-tree";
import { BaseModel } from "../base/base-model";
import { CompanyModel } from "../company/company.model";
import {
  UserRoleEnum,
  UserRoleEnumArray,
  UserRoleEnumDict,
} from "./user.role.enum";

export const UserModel = types
  .compose(
    BaseModel.props({ id: types.maybe(types.identifier) }),
    types.model("User", {
      bitrixId: types.string,
      name: types.string,
      lastName: types.string,
      email: types.string,
      phone: types.string,
      companyId: types.string,
      role: types.enumeration<UserRoleEnum>(UserRoleEnumArray),
      company: types.maybeNull(CompanyModel),
    })
  )
  .views((self) => ({
    get roleName() {
      return UserRoleEnumDict[self.role];
    },
    get fullname() {
      return `${self.lastName} ${self.name}`;
    },
  }));

export interface IUserModel extends Instance<typeof UserModel> {}
export interface IUserSnapshopIn extends SnapshotIn<typeof UserModel> {}
