import { Instance, SnapshotIn, types } from "mobx-state-tree";
import { AwsFileModel } from "../aws-file/aws-file.model";
import { BaseModel } from "../base/base-model";

export const PaymentsFileFModel = types
  .compose(
    BaseModel.props({ id: types.maybe(types.identifier) }),
    types.model().props({
      inserted: types.number,
      updated: types.number,
      total: types.number,
      file: AwsFileModel,
    })
  )
  .views((self) => ({
    get url() {
      return `${self.file.metadata.bucketUrl}/${self.file.key}`;
    },
  }));

export interface IPaymentFile extends Instance<typeof PaymentsFileFModel> {}
export interface IPaymentFileSnapshot
  extends SnapshotIn<typeof PaymentsFileFModel> {}
