import { types } from "mobx-state-tree";
import { NULLABLE_NUMBER, NULLABLE_STRING } from "../extensions/mobx-types";

export const AwsFileMetaDataModel = types.model("AwsFileMetaDataModel").props({
  bucketUrl: types.string,
});

export const AwsFileModel = types.model("AwsFileModel").props({
  acl: NULLABLE_STRING,
  bucket: NULLABLE_STRING,
  contentType: NULLABLE_STRING,
  encoding: NULLABLE_STRING,
  etag: NULLABLE_STRING,
  fieldname: NULLABLE_STRING,
  key: NULLABLE_STRING,
  metadata: AwsFileMetaDataModel,
  mimetype: NULLABLE_STRING,
  originalname: NULLABLE_STRING,
  size: NULLABLE_NUMBER,
  storageClass: NULLABLE_STRING,
});
