import { types } from "mobx-state-tree";
import { toast } from "react-toastify";
import { AppLoadingController } from "../../../components/AppLoading";
import { IColumnAction } from "../../../components/AppTable";
import { confirmDialogContoller } from "../../../dialogs/confirm/confirm-dialog";

import { ApiClient } from "../../../services/api_client";

import { CountryGroupListModel } from "@src/models/country-group/country-group-list.model";
import { CountryGroupModel, ICountryGroup } from "@src/models/country-group/country-group.model";

const CountryGroupControllerModel = types
  .model("CountryGroupController", {
    list: types.optional(CountryGroupListModel, { url: "/country-groups" }),
    isOpenForm: false,
    item: types.maybeNull(types.reference(CountryGroupModel)),
  })
  .actions((self) => {
    return {
      toggleForm() {
        if (self.isOpenForm) {
          self.item = null;
        }
        self.isOpenForm = !self.isOpenForm;
      },
      add() {
        self.isOpenForm = !self.isOpenForm;
      },
      async onAction(action: IColumnAction, row: ICountryGroup) {
        switch (action) {
          case "delete":
            confirmDialogContoller.open(
              async () => {
                try {
                  AppLoadingController.show();
                  await ApiClient.delete(`${self.list.url}/${row.id}`);
                  self.list.refresh();
                  toast.success("Удалено");
                } catch (error) {
                } finally {
                  AppLoadingController.hide();
                }
              },
              "Удаление",
              `Вы уверены что хотите удалить группу?`
            );
            break;
        }
      },
    };
  });

// .views((self) => ({
//   get bitrixCountryGroupsItems():ISelectItem[]{
//     return self.bitrixCountryGroups.map(x=>({label:x.name,value:x.name}))
//   }
// }));
export const CountryGroupController = CountryGroupControllerModel.create();
