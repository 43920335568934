import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "./App.css";
import "react-toastify/dist/ReactToastify.min.css";
import "./customization/yup.locale";

import { MainTheme } from "./customization/theme";
import { AppRouter } from "./router/app_router";
import AppLoadingComponent from "./components/AppLoading";
import AppRouterLoader from "./router/app_router_loader";

function App() {
  return (
    <div>
      <RouterProvider
        router={AppRouter}
        fallbackElement={<AppRouterLoader />}
      />
      <ToastContainer
        closeOnClick
        theme={"dark"}
        closeButton
        pauseOnFocusLoss={false}
        toastStyle={{
          backgroundColor: MainTheme.palette.secondary.main,
        }}
        progressStyle={{
          backgroundColor: MainTheme.palette.primary.main,
        }}
      />
      <AppLoadingComponent />
    </div>
  );
}

export default App;
