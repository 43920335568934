import DictionaryPage from "@src/pages/dashboard/dictionaries/dictionary.page";
import dictionary_router_loader from "@src/pages/dashboard/dictionaries/dictionary_router_loader";
import PaymentsFilesPage from "@src/pages/dashboard/payments-files/payments-files.page";
import PaymentsPage from "@src/pages/dashboard/payments/payments.page";
import { createBrowserRouter, Navigate } from "react-router-dom";
import CompaniesPage from "../pages/dashboard/companies/companies.page";
import DashboardPage from "../pages/dashboard/dashboard.page";
import UsersPage from "../pages/dashboard/users/users.page";
import LoginPage from "../pages/login/login.page";

import AppProtected from "./app_protected";
import CountriesPage from "@src/pages/dashboard/countries/countries.page";
import CountryGroupsPage from "@src/pages/dashboard/country-groups/country-groups.page";
import RequestsPage from "@src/pages/dashboard/requests/requests.page";
import RequestTransportPage from "@src/pages/dashboard/request-transport/request-transport.page";

export const AppRouter = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/",
    element: (
      <AppProtected>
        <DashboardPage />
      </AppProtected>
    ),

    children: [
      {
        path: "requests",
        element: <RequestsPage />,
      },
      {
        path: "companies",
        element: <CompaniesPage />,
      },
      {
        path: "users",
        element: <UsersPage />,
      },
      {
        path: "payments",
        element: <PaymentsPage />,
      },
      {
        path: "countries",
        element: <CountriesPage />,
      },
      {
        path: "country-groups",
        element: <CountryGroupsPage />,
      },
      {
        path: "payments-files",
        element: <PaymentsFilesPage />,
      },
      {
        path: "dictionaries/request-tranport",
        element: <RequestTransportPage />,
      },
      {
        path: "dictionaries/:url",
        element: <DictionaryPage />,
        loader: dictionary_router_loader,
      },
      {
        path: "*",
        element: <Navigate to="requests" />,
      },
      {
        path: "",
        element: <Navigate to="requests" />,
      },
    ],
  },
]);
