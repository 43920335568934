import { types } from "mobx-state-tree";
import { toast } from "react-toastify";
import { AppLoadingController } from "../../../components/AppLoading";
import { IColumnAction } from "../../../components/AppTable";
import { confirmDialogContoller } from "../../../dialogs/confirm/confirm-dialog";
import { CompaniesListModel } from "../../../models/company/companies-list.model";
import { CompanyModel, ICompany } from "../../../models/company/company.model";
import { ApiClient } from "../../../services/api_client";

const CompaniesControllerModel = types
  .model("CompaniesController", {
    list: types.optional(CompaniesListModel, { url: "/companies" }),
    isOpenForm: false,
    item: types.maybeNull(types.reference(CompanyModel)),
  })
  .actions((self) => {
    return {
      toggleForm() {
        if (self.isOpenForm) {
          self.item = null;
        }
        self.isOpenForm = !self.isOpenForm;
      },
      add() {
        self.item = null;
        self.isOpenForm = !self.isOpenForm;
      },
      async onAction(action: IColumnAction, row: ICompany) {
        switch (action) {
          case "delete":
            confirmDialogContoller.open(
              async () => {
                try {
                  AppLoadingController.show();
                  await ApiClient.delete(`${self.list.url}/${row.id}`);
                  self.list.refresh();
                  toast.success("Удалено");
                } catch (error) {
                } finally {
                  AppLoadingController.hide();
                }
              },
              "Удаление",
              `Вы уверены что хотите удалить компанию ${row.name}?`
            );
            break;
          case "edit":
            self.item = row;
            self.isOpenForm = true;
            break;
        }
      },
    };
  });

export const companiesController = CompaniesControllerModel.create();
