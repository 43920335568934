import { Delete, Edit, Search } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  LabelDisplayedRowsArgs,
  LinearProgress,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IBaseList } from "../models/base/base-list-model";
import DvrIcon from "@mui/icons-material/Dvr";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import * as objectPath from "object-path";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
export type IColumnAction = "edit" | "delete" | "view";

export interface IColumn<T extends object> {
  label: string;
  field: string;
  width?: string | number;
  sortKey?: string;
  render?: (record: T, value: any) => React.ReactNode;
}

interface Props<IAnyModelType extends object> {
  list: IBaseList<IAnyModelType>;
  columns: IColumn<IAnyModelType>[];
  actions?: IColumnAction[];
  extraActions?: (row: IAnyModelType) => React.ReactNode | undefined;
  onAction?: (action: IColumnAction, row: IAnyModelType) => void;
}

export default observer(function AppTable<IAnyModelType extends object>({
  list,
  columns,
  actions,
  onAction,
  extraActions,
}: Props<IAnyModelType>) {
  let [searchParams, setSearchParams] = useSearchParams();

  const params = useMemo(() => {
    let _params: any = {};
    searchParams.forEach((v, k) => {
      _params[k] = v;
    });
    return _params;
  }, [searchParams]);

  useEffect(() => {
    list.fetch(params);
  }, [list, params]);

  const onPageChange = useCallback(
    (e: any, page: number) => {
      setSearchParams((prev) => {
        prev.set("page", (page + 1).toString());
        return prev;
      });
    },
    [setSearchParams]
  );
  const onSearch = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
      if (e?.key === "Enter") {
        setSearchParams((prev) => {
          if (e.currentTarget.value) {
            prev.set("searchText", e.currentTarget.value);
          } else {
            prev.delete("searchText");
          }
          return prev;
        });
      }
    },
    [setSearchParams]
  );
  const activeSort: string | undefined = params["sort"];
  const onSort = (field: string) => {
    let sort = field;
    if (activeSort?.startsWith(field)) {
      sort = `-${field}`;
    }
    setSearchParams((prev) => {
      prev.set("sort", sort);
      return prev;
    });
  };
  const labelDisplayedRows = useCallback(({ from, to, count }: LabelDisplayedRowsArgs) => {
    return `${from}–${to} из ${count !== -1 ? count : `больше чем ${to}`}`;
  }, []);

  const [searchValue, setSearchValue] = useState(params["searchText"]);

  useEffect(() => {
    setSearchValue(params.searchText);
  }, [params.searchText]);

  return (
    <Box>
      <Box mb={2}>
        <OutlinedInput
          fullWidth
          placeholder="Поиск"
          value={searchValue || ""}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={onSearch}
          startAdornment={
            <InputAdornment position="start">
              <Search color="primary" />
            </InputAdornment>
          }
        />
      </Box>
      <Box bgcolor={"#fff"} maxWidth="100%" overflow={"visible"}>
        <TableContainer style={{ minHeight: 400, overflowX: "visible" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((x, idx) => {
                  const sortField = x.sortKey ?? x.field;
                  const active = activeSort?.replace("-", "") === sortField;
                  return (
                    <TableCell
                      key={`${x.field}_${idx}`}
                      width={x.width}
                      variant="head"
                      sortDirection={activeSort?.startsWith("-") ? "desc" : "asc"}
                    >
                      <TableSortLabel
                        active={active}
                        IconComponent={active ? ExpandLessIcon : () => <UnfoldMoreIcon fontSize="small" />}
                        direction={activeSort?.startsWith("-") ? "desc" : "asc"}
                        onClick={() => onSort(sortField)}
                      >
                        {x.label}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
                {(actions?.length || extraActions) && <TableCell>Действие</TableCell>}
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow hover={false}>
                <TableCell height={1} colSpan={columns.length} padding={"none"}>
                  {list.loading && <LinearProgress />}
                </TableCell>
              </TableRow>
              {list.items.map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={index}>
                    {columns.map((column, index) => {
                      const key = column.field;
                      const render = column.render;

                      const value = render ? render(row, objectPath.get(row, key)) : objectPath.get(row, key);
                      return (
                        <TableCell width={column.width} key={`${key}_${index}`}>
                          {value}
                        </TableCell>
                      );
                    })}
                    {(actions?.length || extraActions) && (
                      <TableCell>
                        {actions?.includes("edit") && (
                          <Tooltip title="Редактировать">
                            <IconButton onClick={() => onAction && onAction("edit", row)}>
                              <Edit color="primary" />
                            </IconButton>
                          </Tooltip>
                        )}
                        {actions?.includes("delete") && (
                          <Tooltip title="Удалить">
                            <IconButton onClick={() => onAction && onAction("delete", row)}>
                              <Delete color="error" />
                            </IconButton>
                          </Tooltip>
                        )}
                        {extraActions && extraActions(row)}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {!list.loading && list.items.length === 0 && (
            <Box
              sx={{
                height: "100%",
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                backgroundColor: "#fff",
                minHeight: 300,
              }}
            >
              <DvrIcon color="secondary" sx={{ fontSize: 100 }} />
              <Typography mt={1} color="secondary" fontSize={18}>
                Данных нет
              </Typography>
            </Box>
          )}
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={list.count}
          rowsPerPage={list.rowsPerPage}
          showFirstButton
          showLastButton
          page={list.page}
          labelDisplayedRows={labelDisplayedRows}
          onPageChange={onPageChange}
        />
      </Box>
    </Box>
  );
});
